import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-employer-match',
  templateUrl: './employer-match.component.html',
  styleUrls: ['./employer-match.component.scss']
})
export class EmployerMatchComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
