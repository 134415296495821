export const petfinderMock = {
    "animals": [
        {
            "id": 124,
            "organization_id": "NJ333",
            "url": "https://www.petfinder.com/petdetail/49920030",
            "type": "Cat",
            "species": "Cat",
            "breeds": {
                "primary": "Domestic Short Hair",
                "secondary": null,
                "mixed": false,
                "unknown": false
            },
            "colors": {
                "primary": "Tortoiseshell",
                "secondary": null,
                "tertiary": null
            },
            "age": "Adult",
            "gender": "Female",
            "size": "Medium",
            "coat": "Short",
            "name": "Barnabus",
            "description": "Nebula is a shorthaired, shy cat. She is very affectionate once she warms up to you.",
            "photos": [
                {
                    "small": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=100",
                    "medium": "https://dl5zpyw5k3jeb.cloudfront.net/photos/pets/49920030/1/?bust=1640388733&width=130",
                    "large": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=600",
                    "full": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081"
                }
            ],
            "videos": [
                {
                    "embed": "<iframe src=\"https://www.youtube.com/embed/xaXbs1fRFRM\" frameborder=\"0\" allowfullscreen></iframe>"
                }
            ],
            "status": "adoptable",
            "attributes": {
                "spayed_neutered": true,
                "house_trained": true,
                "declawed": false,
                "special_needs": false,
                "shots_current": true
            },
            "environment": {
                "children": false,
                "dogs": true,
                "cats": true
            },
            "tags": [
                "Cute",
                "Intelligent",
                "Playful",
                "Happy",
                "Affectionate"
            ],
            "contact": {
                "email": "petfindertechsupport@gmail.com",
                "phone": "555-555-5555",
                "address": {
                    "address1": null,
                    "address2": null,
                    "city": "Jersey City",
                    "state": "NJ",
                    "postcode": "07097",
                    "country": "US"
                }
            },
            "published_at": "2018-09-04T14:49:09+0000",
            "distance": 0.4095,
            "_links": {
                "self": {
                    "href": "/v2/animals/124"
                },
                "type": {
                    "href": "/v2/types/cat"
                },
                "organization": {
                    "href": "/v2/organizations/nj333"
                }
            }
        },
        {
            "id": 124,
            "organization_id": "NJ333",
            "url": "https://www.petfinder.com/dog/action-jackson-53150477/oh/hamilton/animal-adoption-foundation-oh57/",
            "type": "Cat",
            "species": "Cat",
            "breeds": {
                "primary": "Irish Wolfhound",
                "secondary": null,
                "mixed": false,
                "unknown": false
            },
            "colors": {
                "primary": "Tortoiseshell",
                "secondary": null,
                "tertiary": null
            },
            "age": "Young",
            "gender": "Female",
            "size": "Medium",
            "coat": "Short",
            "name": "Action Jackson",
            "description": "Nebula is a shorthaired, shy cat. She is very affectionate once she warms up to you.",
            "photos": [
                {
                    "small": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=100",
                    "medium": "https://dl5zpyw5k3jeb.cloudfront.net/photos/pets/53150477/1/?bust=1633607906&width=1080",
                    "large": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=600",
                    "full": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081"
                }
            ],
            "videos": [
                {
                    "embed": "<iframe src=\"https://www.youtube.com/embed/xaXbs1fRFRM\" frameborder=\"0\" allowfullscreen></iframe>"
                }
            ],
            "status": "adoptable",
            "attributes": {
                "spayed_neutered": true,
                "house_trained": true,
                "declawed": false,
                "special_needs": false,
                "shots_current": true
            },
            "environment": {
                "children": false,
                "dogs": true,
                "cats": true
            },
            "tags": [
                "Cute",
                "Intelligent",
                "Playful",
                "Happy",
                "Affectionate"
            ],
            "contact": {
                "email": "petfindertechsupport@gmail.com",
                "phone": "555-555-5555",
                "address": {
                    "address1": null,
                    "address2": null,
                    "city": "Jersey City",
                    "state": "NJ",
                    "postcode": "07097",
                    "country": "US"
                }
            },
            "published_at": "2018-09-04T14:49:09+0000",
            "distance": 0.4095,
            "_links": {
                "self": {
                    "href": "/v2/animals/124"
                },
                "type": {
                    "href": "/v2/types/cat"
                },
                "organization": {
                    "href": "/v2/organizations/nj333"
                }
            }
        },
        {
            "id": 124,
            "organization_id": "NJ333",
            "url": "https://www.petfinder.com/petdetail/52794421",
            "type": "Cat",
            "species": "Cat",
            "breeds": {
                "primary": "Hound",
                "secondary": null,
                "mixed": false,
                "unknown": false
            },
            "colors": {
                "primary": "Tortoiseshell",
                "secondary": null,
                "tertiary": null
            },
            "age": "Adult",
            "gender": "Female",
            "size": "Medium",
            "coat": "Short",
            "name": "Ernie",
            "description": "Nebula is a shorthaired, shy cat. She is very affectionate once she warms up to you.",
            "photos": [
                {
                    "small": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=100",
                    "medium": "https://dl5zpyw5k3jeb.cloudfront.net/photos/pets/52794421/1/?bust=1633607930&width=130",
                    "large": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=600",
                    "full": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081"
                }
            ],
            "videos": [
                {
                    "embed": "<iframe src=\"https://www.youtube.com/embed/xaXbs1fRFRM\" frameborder=\"0\" allowfullscreen></iframe>"
                }
            ],
            "status": "adoptable",
            "attributes": {
                "spayed_neutered": true,
                "house_trained": true,
                "declawed": false,
                "special_needs": false,
                "shots_current": true
            },
            "environment": {
                "children": false,
                "dogs": true,
                "cats": true
            },
            "tags": [
                "Cute",
                "Intelligent",
                "Playful",
                "Happy",
                "Affectionate"
            ],
            "contact": {
                "email": "petfindertechsupport@gmail.com",
                "phone": "555-555-5555",
                "address": {
                    "address1": null,
                    "address2": null,
                    "city": "Jersey City",
                    "state": "NJ",
                    "postcode": "07097",
                    "country": "US"
                }
            },
            "published_at": "2018-09-04T14:49:09+0000",
            "distance": 0.4095,
            "_links": {
                "self": {
                    "href": "/v2/animals/124"
                },
                "type": {
                    "href": "/v2/types/cat"
                },
                "organization": {
                    "href": "/v2/organizations/nj333"
                }
            }
        },
        {
            "id": 124,
            "organization_id": "NJ333",
            "url": "https://www.petfinder.com/petdetail/52917366",
            "type": "Cat",
            "species": "Cat",
            "breeds": {
                "primary": "Pit Bull Terrier",
                "secondary": null,
                "mixed": false,
                "unknown": false
            },
            "colors": {
                "primary": "Tortoiseshell",
                "secondary": null,
                "tertiary": null
            },
            "age": "Young",
            "gender": "Female",
            "size": "Medium",
            "coat": "Short",
            "name": "Allie",
            "description": "Nebula is a shorthaired, shy cat. She is very affectionate once she warms up to you.",
            "photos": [
                {
                    "small": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=100",
                    "medium": "https://dl5zpyw5k3jeb.cloudfront.net/photos/pets/52917366/1/?bust=1633607936&width=130",
                    "large": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081&width=600",
                    "full": "https://photos.petfinder.com/photos/pets/124/1/?bust=1546042081"
                }
            ],
            "videos": [
                {
                    "embed": "<iframe src=\"https://www.youtube.com/embed/xaXbs1fRFRM\" frameborder=\"0\" allowfullscreen></iframe>"
                }
            ],
            "status": "adoptable",
            "attributes": {
                "spayed_neutered": true,
                "house_trained": true,
                "declawed": false,
                "special_needs": false,
                "shots_current": true
            },
            "environment": {
                "children": false,
                "dogs": true,
                "cats": true
            },
            "tags": [
                "Cute",
                "Intelligent",
                "Playful",
                "Happy",
                "Affectionate"
            ],
            "contact": {
                "email": "petfindertechsupport@gmail.com",
                "phone": "555-555-5555",
                "address": {
                    "address1": null,
                    "address2": null,
                    "city": "Jersey City",
                    "state": "NJ",
                    "postcode": "07097",
                    "country": "US"
                }
            },
            "published_at": "2018-09-04T14:49:09+0000",
            "distance": 0.4095,
            "_links": {
                "self": {
                    "href": "/v2/animals/124"
                },
                "type": {
                    "href": "/v2/types/cat"
                },
                "organization": {
                    "href": "/v2/organizations/nj333"
                }
            }
        }
    ],
    "pagination": {
        "count_per_page": 20,
        "total_count": 320,
        "current_page": 1,
        "total_pages": 16,
        "_links": {}
    }
}