import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as path from '../../common/constants/Path';

@Component({
  selector: 'app-volunteer-page',
  templateUrl: './volunteer-page.component.html',
  styleUrls: ['./volunteer-page.component.scss']
})
export class VolunteerPageComponent implements AfterViewInit {
  path = path;
  @ViewChild('action1') action1:ElementRef;
  @ViewChild('action2') action2:ElementRef;
  constructor(private renderer:Renderer2) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.renderer.setStyle(this.action1.nativeElement, 'height', "initial");
    this.renderer.setStyle(this.action2.nativeElement, 'height', "initial");
    if(event.target.innerWidth>768) {
      this.setHeights();
    }
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  setHeights(): void {
    const height1 = this.action1.nativeElement.offsetHeight;
    const height2 = this.action2.nativeElement.offsetHeight;
    if(height1>height2) { 
      this.renderer.setStyle(this.action2.nativeElement, 'height', height1 + "px");
    }
    else if(height2>height1) {
      this.renderer.setStyle(this.action1.nativeElement, 'height', height2 + "px");
    }
  }

}
