<div class="potm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <hr class="potm-separator">
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12">
                <h1 class="potm-header montserrat-light">Pets of the Month</h1>
            </div>
        </div>
        <app-spinner [display]="spinnerDisplay"></app-spinner>
        <app-error-message [display]="errorDisplay"></app-error-message>
        <div class="row justify-content-center align-items-center" *ngFor="let potm of potmList; let i = index">
            <div class="col-12 col-md-4 text-center">
                <img src="{{ potm.image }}" alt="pet of the month image" id="potm-{{i}}">
            </div>
            <div class="col-12 col-md-8 text-left">
                <h2 class="montserrat-light">{{ potm.name }}</h2>
                <h3 class="montserrat-light">{{ potm.breed }} - {{ potm.gender }} - {{ potm.age }}</h3>
                <p class="montserrat">{{ potm.description }}</p>
            </div>
        </div>
    </div>
</div>