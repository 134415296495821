import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-direct-donation',
  templateUrl: './direct-donation.component.html',
  styleUrls: ['./direct-donation.component.scss']
})
export class DirectDonationComponent implements OnInit {

  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
