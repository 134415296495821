import { Component, OnInit, HostListener, OnChanges } from '@angular/core';
import { ContentfulPotm } from 'src/app/model/contentful.model';
import { ContentfulService } from 'src/app/service/contentful/contentful.service';

@Component({
  selector: 'landing-potm',
  templateUrl: './potm.component.html',
  styleUrls: ['./potm.component.scss']
})
export class PotmComponent implements OnInit {
  spinnerDisplay: boolean = true;  
  errorDisplay: boolean = false;
  potmList:ContentfulPotm[] = [];

  constructor(private contentfulService:ContentfulService) { }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.setPosition();
  }

  ngOnInit(): void {
    this.contentfulService.getPotm().then(res=> {
      for(let potm of res) {
        this.potmList.push(new ContentfulPotm(
          potm.fields.name, potm.fields.breed, potm.fields.gender, potm.fields.age, potm.fields.description, potm.fields.image.fields.file.url
        ));
      }
      this.errorDisplay=false;
      this.spinnerDisplay = false;
    }).catch(error => {
        this.spinnerDisplay=false;
        this.errorDisplay=true;
      });
  }

  setPosition(): void {
    var width = document.getElementById('potm-0').offsetWidth;
    document.getElementById('potm-0').style.height = width + " px";
    document.getElementById('potm-1').style.height = width + " px";
  }

}