<app-header></app-header>
<app-bread-crumbs title="Contact Us" [crumbs]="['Home', 'About']" [href]="['', 'about']"></app-bread-crumbs>
<div class="contact-us">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 class="montserrat-light">Visit Us</h2>
                <p class="montserrat">
                    Animal Adoption Foundation<br>
                    2480 Ross Millville Rd<br>
                    Hamilton, OH 45013
                </p>
                <p class="montserrat">
                    <app-hours></app-hours>
                </p>
                <h2 class="montserrat-light">Reach Out</h2>
                <p class="montserrat">
                    Phone: <a href="tel:{{ path.phone }}" class="link">{{ path.phone }}</a><br>
                    Email: <a href="mailto:{{ path.email }}" class="link">{{ path.email }}</a><br>
                    Facebook: <a href="{{ path.facebook }}" class="link">@AnimalAdoptionFoundation</a><br>
                    Twitter: <a href="{{ path.twitter }}" class="link">@AAFPets</a>
                </p>
            </div>
            <div class="col-12 col-md-6">
                <img src="assets/img/map-location.jpg" class="map-image" alt="AAF location on map">
            </div>
        </div>
        <div class="row justify-content-center form-row">
            <div class="col-12">
                <h2 class="montserrat-light">Please Feel Free to Drop Us a Line!</h2>
            </div>
            <div class="col-12">
                <form name=”EmailForm”>
                    <div class="form-group">
                        <label for="name-input" class="montserrat">Name:</label>
                        <input #name type="text" class="form-control montserrat" id="name-input" required>
                      </div>
                    <div class="form-group">
                        <label for="email-input" class="montserrat">Email address:</label>
                        <input #email type="email" class="form-control montserrat" id="email-input" aria-describedby="emailHelp" required>
                    </div>
                    <div class="form-group">
                        <label for="message-input" class="montserrat">Message:</label>
                        <textarea #message class="form-control montserrat" id="message-input" rows="3" required></textarea>
                      </div>
                    <p class="montserrat" #errorMessage></p>
                    <button (click)="sendEmail()" #button type="button" class="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>