import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as path from '../../common/constants/Path';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements AfterViewInit {

  path = path;
  @ViewChild('action1') action1:ElementRef;
  @ViewChild('action2') action2:ElementRef;
  @ViewChild('action3') action3:ElementRef;
  @ViewChild('action4') action4:ElementRef;
  constructor(private renderer:Renderer2) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.renderer.setStyle(this.action1.nativeElement, 'height', "initial");
    this.renderer.setStyle(this.action2.nativeElement, 'height', "initial");
    this.renderer.setStyle(this.action3.nativeElement, 'height', "initial");
    this.renderer.setStyle(this.action4.nativeElement, 'height', "initial");
    if(event.target.innerWidth>768) {
      this.setHeights();
    }
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  setHeights(): void {
    const height1 = this.action1.nativeElement.offsetHeight;
    const height2 = this.action2.nativeElement.offsetHeight;
    const height3 = this.action3.nativeElement.offsetHeight;
    const height4 = this.action4.nativeElement.offsetHeight;
    if(height1>height2 && height1>height3 && height1>height4) { 
      this.renderer.setStyle(this.action2.nativeElement, 'height', height1 + "px");
      this.renderer.setStyle(this.action3.nativeElement, 'height', height1 + "px");
      this.renderer.setStyle(this.action4.nativeElement, 'height', height1 + "px");
    }
    else if(height2>height1 && height2>height3 && height2>height4) {
      this.renderer.setStyle(this.action1.nativeElement, 'height', height2 + "px");
      this.renderer.setStyle(this.action3.nativeElement, 'height', height2 + "px");
      this.renderer.setStyle(this.action4.nativeElement, 'height', height2 + "px");
    }
    else if(height3>height1 && height3>height2 && height3>height4) {
      this.renderer.setStyle(this.action1.nativeElement, 'height', height3 + "px");
      this.renderer.setStyle(this.action2.nativeElement, 'height', height3 + "px");
      this.renderer.setStyle(this.action4.nativeElement, 'height', height3 + "px");
    }
    else if(height4>height1 && height4>height2 && height4>height3) {
      this.renderer.setStyle(this.action1.nativeElement, 'height', height4 + "px");
      this.renderer.setStyle(this.action2.nativeElement, 'height', height4 + "px");
      this.renderer.setStyle(this.action3.nativeElement, 'height', height4 + "px");
    }
  }

}
