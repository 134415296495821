import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';
import { EventbriteService } from 'src/app/service/eventbrite/eventbrite.service';
import { Event } from 'src/app/model/event.model';

@Component({
  selector: 'app-recurring-events',
  templateUrl: './recurring-events.component.html',
  styleUrls: ['./recurring-events.component.scss']
})
export class RecurringEventsComponent implements OnInit {
  spinnerDisplay: boolean = true;  
  errorDisplay: boolean = false;
  path = path;
  eventList:Event[] = [];

  constructor(private eventbriteService:EventbriteService) { }

  ngOnInit(): void {
    this.eventbriteService.getEvents().subscribe(
      (res)=> {
        for(let event of res.events) {
          this.eventList.push(new Event(
            event.name.text, this.addLinkTagToInnerHTML(event.description.html), event.url, event.logo.url, event.start.local));
        }
        this.errorDisplay=false;
      this.spinnerDisplay = false;},
      (error) => {
        this.spinnerDisplay=false;
        this.errorDisplay=true;
      })
  }

  addLinkTagToInnerHTML(text): string {
    return text.replaceAll("<A", "<A CLASS='link'");
  }
}
