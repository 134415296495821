import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
