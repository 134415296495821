<app-header></app-header>
<app-bread-crumbs title="Our Sponsors" [crumbs]="['Home', 'About']" [href]="['', 'about']"></app-bread-crumbs>
<div class="sponsors">
    <div class="container">
        <div class="row justify-content-center sponsor-row align-items-center">
            <div class="col-12 col-md-4 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/you-to-the-rescue.jpg" alt="you to the rescue logo">
            </div>
            <div class="col-12 col-md-8">
                <h2 class="montserrat-light">
                    You To The Rescue
                </h2>
                <p class="montserrat">
                    You To The Rescue’s resale boutique is now open and we pawsitively can not do it without your support!
                </p>
                <p class="montserrat">
                    Hours: <br>
                    Tuesday-Friday 11-6 <br>
                    Saturday 11-3 <br>
                    Closed Sunday and Monday. <br>
                    9510 Montgomery Road
                </p>
                <p class="montserrat">
                    A portion of the proceeds from all sales benefit the homeless pets at the Animal Adoption Foundation.
                </p>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-center sponsor-row align-items-center">
            <div class="col-12 col-md-3 text-center text-center">
                <img class="sponsor-img" src="assets/img/sponsors/animal-ark.jpg" alt="animal ark logo">
            </div>
            <div class="col-12 col-md-7">
                <h2 class="montserrat-light">
                    Animal Ark Pet Resort
                </h2>
                <p class="montserrat">
                    2150 Struble Road <br>
                    Cincinnati, OH 45231 <br>
                    513-825-PETS
                </p>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-center sponsor-row align-items-center">
            <div class="col-12 col-md-3 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/bill-spade.jpg" alt="bill spade logo">
            </div>
            <div class="col-12 col-md-7">
                <h2 class="montserrat-light">
                    Bill Spade Electric, Heating, & Cooling﻿
                </h2>
                <p class="montserrat">
                    7181 Wesselman Road <br>
                    Cleves, OH 45002 <br>
                    (513) 941-0075 <br>
                    spadeelectric@roadrunner.com
                </p>
                <a href="https://www.billspade.com/" target="_blank">
                    <button class="btn btn-primary montserrat">Learn More</button>
                </a>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-center sponsor-row align-items-center">
            <div class="col-12 col-md-3 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/petsmart.jpg" alt="petsmart logo">
            </div>
            <div class="col-12 col-md-7">
                <h2 class="montserrat-light">
                    Petsmart
                </h2>
                <p class="montserrat">
                    3439 Princeton Rd <br>
                    Hamilton, OH 45011 <br>
                    513-863-6363
                </p>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-center sponsor-row align-items-center">
            <div class="col-12 col-md-3 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/training-tracks.jpg" alt="training tracks logo">
            </div>
            <div class="col-12 col-md-7">
                <h2 class="montserrat-light">
                    Training Tracks
                </h2>
                <p class="montserrat">
                    513-523-WOOF <br>
                    or <br>
                    513-779-STAY
                </p>
                <a href="https://trainingtracks.com/" target="_blank">
                    <button class="btn btn-primary montserrat">Learn More</button>
                </a>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-center sponsor-row align-items-center">
            <div class="col-12 col-md-3 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/eagle-riders.jpg" alt="hamilton west eagle riders logo">
            </div>
            <div class="col-12 col-md-7">
                <h2 class="montserrat-light">
                    Hamilton West Eagle Riders
                </h2>
                <p class="montserrat">
                    117 Smalley Blvd. <br>
                    Hamilton, Ohio 45013 <br>
                    513-863-3986
                </p>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-center sponsor-row align-items-center">
            <div class="col-12 col-md-4 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/matson-money.jpg" alt="matson moneylogo">
            </div>
            <div class="col-12 col-md-4 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/wcpo.jpg" alt="wcpo logo">
            </div>
            <div class="col-12 col-md-4 text-center">
                <img class="sponsor-img" src="assets/img/sponsors/sign-rentals.jpg" alt="best sign rentals logo">
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>