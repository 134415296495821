import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';
@Component({
  selector: 'app-volunteer-events',
  templateUrl: './volunteer-events.component.html',
  styleUrls: ['./volunteer-events.component.scss']
})
export class VolunteerEventsComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
