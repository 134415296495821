import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AnimalResponse, PetFinderResponse } from '../../model/petfinder.model';
import { petfinderMock } from '../../mock/petfinder.mock';

@Injectable({
  providedIn: 'root'
})
export class PetfinderService {

  constructor(private http: HttpClient) { }

  getAnimals():Promise<PetFinderResponse> {
    // return this.http.get<PetFinderResponse>("url");

    return new Promise((resolve) => {
      const observer = {
        next: x => {var response = x; resolve(response)},
        error: err => {var error = err; console.log(error)},
        complete: () => console.log('Observer got a complete notification'),
      };

      of(petfinderMock).subscribe(observer);
    })
  }

}
