<div class="events base-background">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="text-center montserrat-light">Get involved today and change an animal's life!</h2><br>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <p class="small-text text-center montserrat">
                    Pay us a visit at:<br>
                    2480 Ross Millville Rd.<br>
                    Hamilton, OH 45013<br>
                    {{path.phone}}<br><br>
                    <app-hours></app-hours>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <a href="{{path.events}}"><button class="btn btn-primary small-text montserrat info-button">Learn About Our Upcoming Events</button></a>
            </div>
            <div class="col-12 text-center">
                <a href="{{path.about}}"><button class="btn btn-primary small-text montserrat info-button">Learn More About Who We Are</button></a>
            </div>
        </div>
    </div>
</div>