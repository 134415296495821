import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EVENTBRITE } from 'src/app/config/keys';
import { EventBriteResponse } from 'src/app/model/event.model';

@Injectable({
  providedIn: 'root'
})
export class EventbriteService {

  requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + EVENTBRITE.authToken
    }), 
  };

  constructor(private http: HttpClient) { }

  getEvents():Observable<EventBriteResponse> {
    return this.http.get<EventBriteResponse>("https://www.eventbriteapi.com/v3/organizations/" + EVENTBRITE.org + "/events/?status=live&order_by=name_asc&show_series_parent=true", this.requestOptions);
  }

  getError():Observable<EventBriteResponse> {
    return this.http.get<EventBriteResponse>("https://www.eventbriteapi.com/v3/organizations/" + "intentional error" + "/events/?status=live&order_by=name_asc&show_series_parent=true", this.requestOptions);
  }
}
