<app-header></app-header>
<app-bread-crumbs title="Our Members" [crumbs]="['Home', 'About']" [href]="['', 'about']"></app-bread-crumbs>
<div class="members">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="montserrat-light text-center">Board of Directors</h1>
            </div>    
        </div>
        <div class="row justify-content-center">
            <app-error-message display="{{displayError}}"></app-error-message>
            <div class="col-12 col-md-6 col-xl-4" *ngFor="let member of board">
                <app-member name="{{member.name}}" title="{{member.title}}" email="{{member.email}}"></app-member>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h1 class="montserrat-light text-center staff-title">Staff</h1>
            </div>    
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-xl-4" *ngFor="let member of staff">
                <app-member name="{{member.name}}" title="{{member.title}}" phoneNumber="{{member.phoneNumber}}" email="{{member.email}}"></app-member>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>