<app-header></app-header>
<app-bread-crumbs title="External Resources" [crumbs]="['Home', 'About']" [href]="['', 'about']"></app-bread-crumbs>
<div class="base-background resources">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="montserrat-light resource-title">NO KILL SHELTER RESOURCES</h2>
                <a href="http://www.nokillnetwork.org" target="_blank"><p class="montserrat resource-text link">No Kill Network</p></a><br>
                <a href="http://www.petfinder.com" target="_blank"><p class="montserrat resource-text link">Petfinder</p></a><br>
                
                <h2 class="montserrat-light resource-title">TRAINING RESOURCES</h2>
                <a href="http://www.trainingtracks.com" target="_blank"><p class="montserrat resource-text link">Training Tracks</p></a><br>
                <a href="http://www.Garysdoggrooming.com" target="_blank"><p class="montserrat resource-text link">Gary's Dog Grooming</p></a><br>
                <a href="http://www.Goldentouchdogs.com" target="_blank"><p class="montserrat resource-text link">Golden Touch Dogs</p></a><br>
                <a href="http://www.Somuchpetential.com" target="_blank"><p class="montserrat resource-text link">So Much Petential</p></a><br>
                <a href="http://www.Pawslooklisten.com" target="_blank"><p class="montserrat resource-text link">Paws Look Listen</p></a><br>
                <a href="http://www.Dog-abilities.com" target="_blank"><p class="montserrat resource-text link">Dog Abilities</p></a><br>
                
    
                <h2 class="montserrat-light resource-title">OTHER</h2>
                <a href="http://www.greatercincinnatipetpages.com" target="_blank"><p class="montserrat resource-text link">Greater Cincinnati Pet Pages</p></a><br>
                <a href="http://www.adoptashelter.com" target="_blank"><p class="montserrat resource-text link">Adopt a Shelter</p></a><br>
                <a href="http://www.goodsearch.com" target="_blank"><p class="montserrat resource-text link">Good Search</p></a><br>
                <a href="http://www.theanimalrescuesite.com" target="_blank"><p class="montserrat resource-text link">The Animal Rescue Site</p></a><br>
                <a href="http://www.bringfido.com/lodging/" target="_blank"><p class="montserrat resource-text link">Bring Fido</p></a><br>
                <a href="http://www.giveadoggyabone.com" target="_blank"><p class="montserrat resource-text link">Give a Doggy a Bone</p></a><br>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>