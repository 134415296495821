export interface EventBriteResponse {
    events: EventBriteEventResponse[];
}

export interface EventBriteEventResponse {
    name: EventBriteTextOptions;
    logo: EventBriteLogoResponse;
    description: EventBriteTextOptions;
    url: string;
    start: EventBriteStartResponse;
}

export interface EventBriteTextOptions {
    html: string;
    text: string;
}

export interface EventBriteLogoResponse {
    url: string;
}

export interface EventBriteStartResponse {
    local: string;
}

export interface EventBriteDescription{
    content: EventBriteContent[]
}


export interface EventBriteContent {
    nodeType: String
    content: EventBriteContentData[]
}

export interface EventBriteContentData {
    nodeType: String
    content: EventBriteContentData[]
    value: String
}

export class ContentfulEvent {

    constructor(title:string, description:EventBriteDescription, link:string, image_url:string, date:string) {
            this.title = title;
            this.description = [];
            description.content.forEach(descriptionNode => {
                let descriptionValues = [];
                descriptionNode.content.forEach(element => {           
                    if(descriptionNode.nodeType==="unordered-list") {
                        descriptionValues.push(element?.content[0]?.content[0]?.value);
                    }
                    else {
                        descriptionValues.push(element?.value);
                    }
                    
                });
                this.description.push(new EventDescription(descriptionNode.nodeType, descriptionValues));
            })
            this.link = link;
            this.image_url = image_url;
            this.date = date;
        }
    title: string;
    description: EventDescription[];
    link: string;
    image_url: string;
    date: string;
}

export class Event {

    constructor(title:string, description:string, link:string, image_url:string, date:string) {
            this.title = title;
            this.description = description;
            this.link = link;
            this.image_url = image_url;
            this.date = date;
        }
    title: string;
    description: string;
    link: string;
    image_url: string;
    date: string;
}

export class EventDescription {

    constructor(type: String, values: String[]) {
        this.type = type;
        this.values = values;
    }
    type: String
    values: String[]
}