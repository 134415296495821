<app-header></app-header>
<app-bread-crumbs title="Community Events" [crumbs]="['Home', 'Events']" [href]="['', 'events']"></app-bread-crumbs>
<div class="events base-background">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-12">
                <p class="montserrat">Come to our fundraising events that we host for our community! You can check out our <a class="link" target="_blank" href="{{path.facebook}}/events">Facebook events</a> page for more information!</p>
            </div>
        </div>
        <app-spinner [display]="spinnerDisplay"></app-spinner>
        <events-annual-error [display]="errorDisplay"></events-annual-error>
        <div class="events-container">
            <div class="row align-items-center" *ngFor="let event of eventList">
                <div class="col-12 col-md-6">
                    <h2 class="events-header montserrat-light">{{ event.title }}</h2>
                    <div *ngFor="let description of event.description">
                        <p class="events-text montserrat" *ngIf="description.type!='unordered-list'">{{description.values[0]}}</p>
                        <ul *ngIf="description.type=='unordered-list'">
                            <li class="montserrat" *ngFor="let value of description.values">{{value}}</li>
                        </ul>
                    </div>
                    <p class="events-text montserrat">{{ event.date }}</p>
                    <div class="events-button-div">
                        <a target="_blank" href="{{ event.link }}"><button class="btn btn-primary events-button">Learn More</button></a>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-center">
                    <img src="{{ event.image_url }}" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>