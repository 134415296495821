import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-doggie-dating',
  templateUrl: './doggie-dating.component.html',
  styleUrls: ['./doggie-dating.component.scss']
})
export class DoggieDatingComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
