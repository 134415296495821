<div class="container news-container">
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <h2 class="montserrat-light">ATTENTION!</h2>
        </div>
        <div class="col-12">
            <p class="montserrat">We are now open to the public during normal business hours so please stop by and see us.
                We are still doing adoptions by appointment only with a qualified application.  <br><br>
                *The AAF is only adopting pets to individuals and families who live within a 50 mile radius of the shelter.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <h2 class="montserrat-light">MICROCHIP YOUR PET FOR JUST $20</h2>
        </div>
        <div class="col-12">
            <p class="montserrat">
                The AAF offers public microchipping for your pet.  Help ensure your pets' safety by taking just a few minutes of your time to have your beloved pet chipped...it could really make a difference if your pet were to ever get lost. To schedule a microchip, please call the shelter at {{path.phone}}.  It will only take just a few minutes of your time!
            </p>
        </div>
        <div class="col-12 col-md-6 text-center">
            <img src="assets/img/icon/found_animals.png" alt="">
        </div>
    </div>
</div>