<app-header></app-header>
<app-bread-crumbs title="Foster A Pet" [crumbs]="['Home', 'Adopt']" [href]="['', 'adopt']"></app-bread-crumbs>
<div class="foster base-background">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <p class="montserrat">
                    Love animals, but unable to make a long-term commitment? Consider becoming a foster parent! Foster care is an opportunity to care for an animal that's not yet ready for adoption, such as kittens and puppies too young for adoption, cats and dogs nursing litters that need a quiet home to thrive, cats and dogs recovering from surgery, illness or injury, senior pets or pets with special needs.<br><br>
                    AAF regularly rescues animals that would be euthanized due to a lack of space at other shelters.  As a foster parent you are helping an animal in need and you're also providing that added space needed to bring another animal to the shelter- that's saving another life!<br><br>
                    AAF provides medical care plus the basic needs of each foster care pet including food, bowls, crate, and litter.<br><br>
                    We will match the perfect foster care pet to your individual needs.  Whether you work or stay at home, have a small amount of time or want to make a full-time commitment, we have a pet that needs your help.  Fostering lets you make a difference in an animal's life.  We are looking for caring, compassionate individuals- is that you?  Come to our volunteer orientation Saturdays (please click on the volunteer link above to find out more). <br><br>
                </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <a target="_blank" href="{{path.foster_application}}"><button class="btn btn-primary montserrat">Pet Foster Form</button></a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>