<app-header></app-header>
<app-bread-crumbs title="Doggie Dating" [crumbs]="['Home', 'Adopt']" [href]="['', 'adopt']"></app-bread-crumbs>
<div class="doggie-dating base-background">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <p class="montserrat">
                    The Animal Adoption Foundation developed the Doggie Date (DD) program to help our homeless pets retain and/or develop important social skills during their time here with us. This program is available for AAF volunteers who have attended AAF's General Orientation session.<br><br>
                    The DD program offers the animals an opportunity to get away from the loneliness of shelter life and experience the real world around them. Through personal interaction with you and other volunteers, they become happier, healthier pets and often experience less stress during their transition into a permanent new home when they are adopted. If you would like to help by taking one of our pets on a date, first review the following guidelines then see one of our staff members here to find a perfect match. We have hours of unconditional love just waiting here for you.<br><br>
                    The DD program does not require you to register in advance; however, it helps the shelter in planning if you DO register (to register, go to: http://doggiedateataaf.eventbrite.com).  If you don't register, you may prefer to to call ahead to be sure someone is available to assist you. Because all our animals are waiting for permanent homes, they must be here at the shelter during our regular adoption hours so they can be viewed by the public and are unavailable for dates during the following hours:<br><br>
                    Tuesday through Friday 4:00pm - 7:00pm 
                        Saturday & Sunday 1:00pm - 4:00pm<br><br>
                    You have the option of taking your date out for just the afternoon or overnight (provided you have permission from your landlord). Any family or housemates you may have should be made aware of your plans to bring home the animal even if it is for a short time. You should also try to minimize the traffic in and out of your home during your date.<br><br>
                    -If you and your date are just out for the afternoon, you will need to have them back at the shelter by 7:00pm on the day of your date.
                        -If your date will be spending the night you need to have them back at the shelter before the next scheduled adoption hours (see above).<br><br>
                    We will provide you with all of the necessities you will need for your date. A collar, a leash, food and treats are available for dogs. Toys are also available upon request. It is important to remember that all items must be returned to the shelter when you bring back your date.<br><br>
                    Dogs must wear their collar and remain on the leash at all times, this includes any time spent indoor. Even if your home has a fenced yard, dogs must not be allowed to run off leash. Under no circumstances should your date be left in the care of others, they must be in your presence at all times.<br><br>
                    You should carefully monitor all household traffic in and out of the home to minimize their chance of escape. Under no circumstances should your date be left in the care of others, they must be in your presence at all times.  The pet is your responsibility.<br><br>
                    Finally, we ask that that after your date you take a few moments and complete a DD evaluation. This information allows us to better understand the personality and behavior of the pet in social situations and aids us in their placement.<br><br>
                    Feel free to call us any time should you have any questions about the DD Program. You can reach us at: {{path.phone}}.
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>