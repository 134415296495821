import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { SendInBlueEmail } from 'src/app/model/sendinblue.model';
import { SendinblueService } from 'src/app/service/sendinblue/sendinblue.service';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements AfterViewInit {
  @ViewChild('errorMessage') errorMessage:ElementRef;
  @ViewChild('name') name:ElementRef;
  @ViewChild('email') email:ElementRef;
  @ViewChild('message') message:ElementRef;
  @ViewChild('button') button:ElementRef;

  path = path;
  wait:boolean = false;
  
  constructor(private sendinblueService:SendinblueService, private renderer:Renderer2) { }

  ngAfterViewInit(): void {
  }

  sendEmail():void {
    if(this.name.nativeElement.value && this.email.nativeElement.value && this.message.nativeElement.value) {
      let email:SendInBlueEmail = new SendInBlueEmail(this.name.nativeElement.value, 
        this.email.nativeElement.value, this.message.nativeElement.value);
      this.sendinblueService.sendEmail(email).subscribe(data => {
        this.renderer.setStyle(this.errorMessage.nativeElement, 'display', 'block');
        this.errorMessage.nativeElement.innerHTML = "Email sent!";
        this.renderer.addClass(this.button.nativeElement, 'disable-button');
      }, error=> {
        this.renderer.setStyle(this.errorMessage.nativeElement, 'display', 'block');
        this.errorMessage.nativeElement.innerHTML = "Error sending email.";
        this.renderer.addClass(this.button.nativeElement, 'disable-button');
      });
    }
    else {
      this.renderer.setStyle(this.errorMessage.nativeElement, 'display', 'block');
      this.errorMessage.nativeElement.innerHTML = "Please fill out all fields.";
    }
  }

}
