<app-header></app-header>
<app-bread-crumbs title="Direct Donation" [crumbs]="['Home', 'Donate']" [href]="['', 'donate']"></app-bread-crumbs>
<div class="direct-donation-top base-background">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <h2 class="montserrat-light direct-donation-h1">Give money directly to the Animal Adoption Foundation</h2>
                <p class="montserrat upper-text">
                    Make a payment online to fund our efforts to provide homeless cats and dogs with food, medical care, shelter, and love! 
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a target="_blank" href="{{path.donation_application}}"><button class="btn btn-primary montserrat">Donate Online</button></a>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <p class="montserrat upper-text">
                    You have the option to determine if the money goes to our general fund or the Animal Angels Fund
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a href="{{path.donate}}/{{path.animal_angels}}"><button class="btn btn-primary montserrat">Learn about Animal Angels Fund</button></a>
            </div>
        </div>
    </div>
</div>
<div class="direct-donation-address">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <p class="montserrat address-text">Or send your donation to:</p>
                <p class="montserrat address">
                    The Animal Adoption Foundation<br>
                    2480 Ross Millville Road<br>
                    Hamilton, OH 45013
                </p>
                <p class="montserrat address-text">
                    Please make all checks payable to The Animal Adoption Foundation. We can be contacted at {{path.phone}} if you have any questions
                </p>
            </div>
        </div>
    </div>
</div>
<div class="direct-donation-bottom base-background">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <p class="montserrat bottom-text">
                    Find out if your employer takes part in our matching gift program!
                </p>
                <a href="{{path.donate}}/{{path.employer_match}}"><button class="btn btn-primary montserrat bottom-button">Employer Match</button></a>
                <p class="montserrat bottom-text">
                    If you would like to honor the passing of beloved pet, you may make a donation to the AAF as a tribute to the wonderful memories you shared. By making a memorial donation, you will be able to help make the lives of less fortunate animals a little brighter, while acknowledging the love and admiration you had for your pet. We will gladly print your memorial donation in our newsletter.
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>