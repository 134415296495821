<app-header></app-header>
<app-bread-crumbs title="Volunteer Roles" [crumbs]="['Home', 'Volunteer']" [href]="['', 'volunteer']"></app-bread-crumbs>
<div class="roles base-background">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <h3 class="montserrat-light page-subtitle">Behind the Scenes</h3>
                <p class="montserrat">
                    We rely on volunteers to get the equipment and supplies ready that are needed to care for the animals. Check out the white and purple binder in the volunteer room for instructions for lots of different activities. Complete the volunteer application and Level 1 - New Volunteer Orientation to get started.
                </p>
                <h3 class="montserrat-light page-subtitle">Cat Socialization</h3>
                <p class="montserrat">
                    The cats appreciate when you brush them and play with them. Get ideas on cat room activities from the white and purple binder in the volunteer room. Complete the volunteer application and Level 1 - New Volunteer Orientation to get started.
                </p>
                <h3 class="montserrat-light page-subtitle">Committee Membership</h3>
                <p class="montserrat">
                    If you like planning events, creating marketing materials, or fundraising, we would love for you to visit one of our committee meetings. Please  email your interests and we will give you the upcoming schedule.
                </p>
                <h3 class="montserrat-light page-subtitle">Dog Care</h3>
                <p class="montserrat">
                    Complete Level 2 orientation to serve in dog care. Level 2 volunteers do clicker training exercises to reduce stress in dogs and improve adoptability. They help with cleaning for wellness. They provide enrichment activities like puzzle toys and kennel visits.
                </p>
                <h3 class="montserrat-light page-subtitle">Dog Care, Advanced</h3>
                <p class="montserrat">
                    Complete Level 3 orientation to serve in advanced dog care. Level 3 volunteers walk dogs and work with dogs in the play yards. These volunteers are trained in AAF procedures (such as the leash and harness) as well as canine play behavior and body language
                </p>
                <h3 class="montserrat-light page-subtitle">Foster</h3>
                <p class="montserrat">
                    Foster care is an opportunity to care for an animal that's not yet ready for adoption, such as kittens and puppies too young for adoption, cats and dogs nursing litters that need a quiet home to thrive, cats and dogs recovering from surgery, illness or injury, senior pets or pets with special needs. Learn more about volunteering as a Foster.
                </p>
                <h3 class="montserrat-light page-subtitle">Front Desk</h3>
                <p class="montserrat">
                    Today, someone will have their first experience with an animal rescue center. We need your help making them feel welcome at AAF. They often do not know how AAF might be able to help them with activities such as helping a stray or adopting from the shelter. The Front Desk Assistant welcomes and assists individuals who visit or call AAF. Front Desk Assistant volunteer serve on a regular three-hour shift each week. If you are available to volunteer on a regular basis, contact us by phone {{path.phone}} or email.
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>