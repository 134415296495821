import { AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import * as path from '../../common/constants/Path';

@Component({
  selector: 'landing-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements AfterViewInit {

    
  @ViewChild('actionDiv1') actionDiv1:ElementRef;
  @ViewChild('actionDiv2') actionDiv2:ElementRef;
  @ViewChild('actionDiv3') actionDiv3:ElementRef;

  constructor(private renderer:Renderer2) { }
  path = path;
  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.setPosition();
  }

  ngAfterViewInit(): void {
    this.setPosition();
  }

  setPosition(): void {
    var divWidth = this.actionDiv1.nativeElement.offsetWidth;
    this.renderer.setStyle(this.actionDiv1.nativeElement, 'height', divWidth+'px');
    this.renderer.setStyle(this.actionDiv2.nativeElement, 'height', divWidth+'px');
    this.renderer.setStyle(this.actionDiv3.nativeElement, 'height', divWidth+'px');
  }

}