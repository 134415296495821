import { AfterViewInit, Component } from '@angular/core';
import * as path from '../../../common/constants/Path';
import { ContentfulService } from 'src/app/service/contentful/contentful.service';
import { ContentfulMember } from 'src/app/model/contentful.model';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements AfterViewInit {
  path = path;
  displayError:boolean = false;
  members:ContentfulMember[] = [];
  board: ContentfulMember[] = [];
  staff: ContentfulMember[] = [];

  constructor(private contentfulService:ContentfulService) {}

  ngAfterViewInit(): void {
    try{
      this.contentfulService.getMembers().then(res=>{
        this.members = this.mapContentfulResponse(res);
      })
    } catch(error) {
      this.displayError = true;
    }

  }

  mapContentfulResponse(response):ContentfulMember[] {
    let members:ContentfulMember[] = [];
    response.forEach(member=> {
      members.push(new ContentfulMember(member?.fields?.name, member?.fields?.title, member?.fields?.email, member?.fields?.phoneNumber, member?.fields?.boardMember, member?.fields?.order))
    });
    members = members.sort(function(a, b) {
      return a.order - b.order;
    });
    members.forEach(member=>{
      member.isBoardMember ? this.board.push(member) : this.staff.push(member);
    })
    return members;
  }
}
