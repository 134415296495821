<div class="adopt-widget-search" id="availability">
    <form action="{{path.adopt}}#adopt-widget-results">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-9">
                    <div class="container">
                        <div class="row text-center adopt-widget-row">
                            <div class="col-12">
                                <h2 class="adopt-widget-text montserrat">Find your perfect match!</h2>
                            </div>
                        </div>
                        <div class="row text-center adopt-widget-row">
                            <div class="col-12">
                                <p class="adopt-widget-text montserrat">Select the type of animal, age, size, or  breed you are looking for
                                    and our Petfinder will show you the animals we currently have
                                    available for adoption.</p>
                            </div>
                        </div>
                        <div class="row text-center desktop-submit-row">
                            <div class="col-12">
                                <input type="submit" class="btn btn-secondary montserrat" value="Search">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <label class="adopt-widget-label montserrat" for="animal">Animal</label><br>
                                <select #animal id="animal" name="animal" class="montserrat search-input custom-select">
                                    <option value="all">All</option>
                                    <option value="dog">Dog</option>
                                    <option value="cat">Cat</option>
                                  </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="adopt-widget-label montserrat" for="gender">Gender</label><br>
                                <select #gender id="gender" name="gender" class="montserrat search-input custom-select">
                                    <option value="all">All</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="adopt-widget-label montserrat" for="age">Age</label><br>
                                <select #age id="age" name="age" class="montserrat search-input custom-select">
                                    <option value="all">All</option>
                                    <option value="young">Young</option>
                                    <option value="adult">Adult</option>
                                  </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="adopt-widget-label montserrat" for="size">Size</label><br>
                                <select #size id="size" name="size" class="montserrat search-input custom-select">
                                    <option value="all">All</option>
                                    <option value="small">Small</option>
                                    <option value="medium">Medium</option>
                                    <option value="large">Large</option>
                                  </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="adopt-widget-label montserrat" for="breed">Breed</label><br>
                                <input #breed placeholder="Any" name="breed" type="text" class="montserrat search-input form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row text-center mobile-submit-row">
                        <div class="col-12">
                            <input type="submit" class="btn btn-secondary montserrat" value="Search">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="adopt-widget-results base-background" id="adopt-widget-results">
    <div class="container">
        <div class="row text-center">
            <div class="col-12 adopt-widget-results-header">
                <h1 class="montserrat-light adopt-widget-results-h1">Adoptable Pets</h1>
                <p class="montserrat">Click a pet's image to learn more about them!</p>
            </div>
        </div>
        <app-spinner [display]="spinnerDisplay"></app-spinner>
        <app-adopt-error [display]="errorDisplay"></app-adopt-error>
        <div class="row text-center">
            <div class="col-12">
                <p #noPetsMessage class="montserrat no-pets-message">No pets found, try widening your search.</p>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-6 col-md-3 adopt-widget-results-div" *ngFor="let animal of animalList">
                <a (click)="onPetClick(animal)" href="javascript:void(0)"><img src="{{animal.photo}}" class="adopt-widget-results-img montserrat" alt=""></a>
                <h3 class="adopt-widget-results-name montserrat">{{animal.name}}</h3>
                <p class="adopt-widget-results-info montserrat">
                    {{animal.breed}}<br>
                    {{animal.size}}, {{animal.age}}
                </p>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<button #courtesyModalButton type="button" class="btn btn-primary d-none" data-toggle="modal" data-target="#courtesyModal"></button>
<div #courtesyModal class="modal fade" id="courtesyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="adopt-widget-text montserrat">Courtesy Post</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="text-white">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="adopt-widget-results-info montserrat">
            This pet is not available at AAF, please reach out to <a href="tel:{{courtesyModalNumber}}">{{courtesyModalNumber}}</a> for more information about adopting this pet.
        </p>
      </div>
    </div>
  </div>
</div>