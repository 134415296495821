import { AfterViewInit, Component } from '@angular/core';
import { ContentfulService } from 'src/app/service/contentful/contentful.service';
import { HOURS } from '../constants/Constants';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent implements AfterViewInit {
  hours: string[];
  constructor(private contentfulService:ContentfulService) {}

  ngAfterViewInit(): void {
    try{
      this.contentfulService.getHours().then(res=>{
        this.hours = this.mapContentfulResponse(res);
      })
    } catch(error) {
      this.hours = HOURS
    }

  }

  mapContentfulResponse(response):string[] {
    let hours = [];
    response[0]?.fields?.hours?.content.forEach(element => {
      hours.push(element?.content[0]?.value);
    });
    return hours;
  }

}
