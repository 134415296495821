<app-header></app-header>
<app-bread-crumbs title="Orientation and Training" [crumbs]="['Home', 'Volunteer']" [href]="['', 'volunteer']"></app-bread-crumbs>
<div class="training base-background">
    <div class="container training-top">
        <div class="row justify-content-center">
            <div class="col-12">
                <p class="montserrat">
                    Thank you for your interest in volunteering at AAF! You can become an official AAF volunteer by completing an application and attending one or more sessions of training. How many sessions you complete depends on whether you want to volunteer with cats or dogs. Briefly, all shelter volunteers begin their training experience by attending Level 1-New Volunteer Orientation. For volunteers who want to work with dogs, attending three additional sessions of training (Level 2, 3 and 4) is required to be fully trained for working with our dogs.<br><br>
                    If you have trouble registering for any of the volunteer orientation/training sessions, please write {{path.email}} for assistance. Please read below to learn more about registering and what's involved.<br><br>
                    Pre-Registration is required for all volunteer training sessions.<br><br>
                    Here's how to register: <br><br>                
                </p>
            </div>
            <div class="col-12 col-lg-10 ol-column">
                <ol>
                    <li>
                        <p class="montserrat">Complete the online volunteer application and you will receive an immediate confirmation email with a link and access code to sign up for Level 1 - New Volunteer Orientation. There are lots of ways you can volunteer at Level 1. </p>
                    </li>
                    <li>
                        <p class="montserrat">When you register for Level 1, you will receive an immediate confirmation email with a link and access code to sign up for Level 2 -  Dog Care Orientation. If you are interested the dog care volunteer roles, please register for Level 2.</p>
                    </li>
                    <li>
                        <p class="montserrat">When you register for Level 2, you will receive an immediate confirmation email with a link and access code to sign up for Level 3 - Advanced Dog Care Orientation. If you are interested in the advanced dog care volunteer roles, please register for Level 3 and Level 4.</p>
                    </li>
                </ol>
            </div>
            <div class="col-12">
                <p class="montserrat-italic">
                    We no longer list upcoming dates for orientation because they are available to you through the link and access code to sign up for each level. <span class="underlined">If there are no sign-ups 24 hours prior to the orientation, that session will be canceled.</span>
                </p>
                <p class="montserrat">
                    Please come prepared to stand and walk for the duration of our orientations. We are not able to provide refreshments during our orientations. Special accommodation requests should be sent to {{path.email}}. Please do not schedule your orientation until you have received verification that we can accommodate your request.               
                </p>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row bg-color">
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row align-items-center level-row">
                        <div class="col-12 text-center">
                            <h2 class="montserrat bg-color-text">Level 1 - New Volunteer Orientation</h2>
                        </div>
                        <div class="col-12 col-lg-5 text-center">
                            <img src="assets/img/volunteer/level_1.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-7">
                            <p class="montserrat bg-color-text">Level 1 is scheduled on alternating Saturdays from 10:00AM - 11:00AM. Please note that Level 2 is scheduled immediately after Level 1 concludes, for those who would like to complete both levels on the same day.  Please click on the link below to register online...it's easy!</p>
                            <br>
                            <a href="{{ path.training_1 }}" target="_blank"><button class="btn btn-secondary">Register for Level 1</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row bg-base base-background">
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row align-items-center level-row">
                        <div class="col-12 text-center">
                            <h2 class="montserrat bg-base-title">Level 2 - Dog Care Orientation</h2>
                        </div>
                        <div class="col-12 col-lg-5 text-center mobile-img">
                            <img src="assets/img/volunteer/level_2.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-7">
                            <p class="montserrat">
                                This training class is for AAF volunteers who want to learn more about serving with AAF dogs, have completed Level 1 - New Volunteer Orientation and are 15 years of age or older, <br><br>
                                This 2-hour indoor program covers kennel safety procedures, canine body language, clicker training exercises to reduce stress in dogs and improve adoptability, cleaning for wellness protocols, enrichment, and Kong preparation. <br><br>
                                 Level 2 is scheduled on alternating Saturdays from 11:00AM - 1:00PM. This is immediately after Level 1 concludes. Volunteers may choose to complete Level 2 on the same day or wait for a future date.  Please click on the link below to register for Level 2 Orientation...it's easy!</p>
                            <br>
                            <a href="{{ path.training_2 }}" target="_blank"><button class="btn btn-primary">Register for Level 2</button></a>
                        </div>
                        <div class="col-12 col-lg-5 text-center desktop-img">
                            <img src="assets/img/volunteer/level_2.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row bg-color">
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row align-items-center level-row">
                        <div class="col-12 text-center">
                            <h2 class="montserrat bg-color-text">Level 3 - Advanced Dog Care Orientation</h2>
                        </div>
                        <div class="col-12 col-lg-5 text-center">
                            <img src="assets/img/volunteer/level_3.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-7">
                            <p class="montserrat bg-color-text">(With the addition of the Mutt Mentor Program).<br><br>
                                This training class is for AAF volunteers who want to become qualified for advanced volunteering with dogs. Level 2 - Dog Care Orientation is a pre-requisite for this training.<br><br>
                                You will develop leash skills for walking dogs, review various leash and harnesses, discuss and observe canine play behavior and body language, learn about and work with AAF dogs in the dog agility play yards, and refine skills from Level 2. This orientation is scheduled on alternating Saturdays from 11:00AM - 1:00PM.<br><br>
                                Once you have completed Level 3 Training you will be matched up with one of our Mutt Mentor volunteers.  This volunteer will take you under his/her wing for a total of five hours (this time can be broken up into different blocks) and show you the ropes so that you are completely comfortable interacting with our lovable shelter dogs.  It's a great way to give back to our four-legged friends and a great way to meet new friends too!  Please click on the link below to register for Level 3...it's easy!
                            </p>
                            <br>
                            <a href="{{ path.training_3 }}" target="_blank"><button class="btn btn-secondary">Register for Level 3</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row bg-base base-background">
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row align-items-center level-row">
                        <div class="col-12 text-center">
                            <h2 class="montserrat bg-base-title">Level 4 - Mutt Mentoring</h2>
                        </div>
                        <div class="col-12 col-lg-5 text-center mobile-img">
                            <img src="assets/img/volunteer/level_4.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-7">
                            <p class="montserrat">
                                Once you've completed Level 3-Advanced Dog Care Orientation, you'll be matched with one of our fantastic Mutt Mentor volunteers. This volunteer will take you under his/her wing for a total of 5 hours and show you the ropes. Your five hours of mentoring is designed to be broken up into two sessions; our mentors are currently available every day of the week and you can pick your times when you register for these sessions.<br><br>
                                We hope at the end of this experience you'll feel comfortable interacting with the shelter's dogs and will know your way around. We also hope you'll finish up your Mutt Mentoring experience knowing about safety procedures as well as different dog-related programs and ways to be involved.<br><br> 
                                Please click on the link below to register for both of your Mutt Mentor sessions...it's easy and the dogs will love you for it! 
                            </p>
                            <br>
                            <a href="{{ path.training_4 }}" target="_blank"><button class="btn btn-primary">Register for Level 4</button></a>
                        </div>
                        <div class="col-12 col-lg-5 text-center desktop-img">
                            <img src="assets/img/volunteer/level_4.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>