import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnChanges {
  @Input() display: boolean;
  @ViewChild('spinner') spinner:ElementRef;

  constructor(private renderer:Renderer2) { }

  ngOnChanges(): void {
    this.setSpinnerDisplay();
  }

  setSpinnerDisplay() {
    if(!!this.spinner && !this.display) {
      this.renderer.setStyle(this.spinner.nativeElement, 'display', 'none');
    }
    else if(!!this.spinner && this.display) {
      this.renderer.setStyle(this.spinner.nativeElement, 'display', 'block');
    }
  }

}
