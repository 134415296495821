<app-header></app-header>
<app-bread-crumbs title="Community Events" [crumbs]="['Home', 'Volunteer']" [href]="['', 'volunteer']"></app-bread-crumbs>
<div class="events base-background">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-12">
                <p class="montserrat">Thank you for your interest in volunteering. You can sign up for any community event below or become an official AAF volunteer by completing the volunteer application and attending one or more levels of orientation.</p>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-12">
                <hr>
            </div>
            <div class="col-12 col-md-6">
                <h2 class="community-events-header montserrat-light">AAF Walk-A-Hound Program Expands!</h2>
                <p class="community-events-text montserrat">
                    Chase away those winter blues and help one of our shelter dogs by participating in our Walk-A-Hound Program.  We are now offering a Sunday program from 11 AM-12 PM in addition to our already established Monday, Thursday, Friday and Saturday times.  You don't have to be a volunteer to participate and it's as easy as clicking on the link below to sign up...and best of all it's free of charge!  Hope to see you soon.
                </p>
                <div class="community-events-button-div">
                    <a href=""><button class="btn btn-primary community-events-button">Register for Walk-a-Hound</button></a>
                </div>
            </div>
            <div class="col-12 col-md-6 text-center">
                <img src="assets/img/fliers/aaf_walk_a_hound.jpg" alt="">
            </div>

        </div>
        <div class="row align-items-center">
            <div class="col-12">
                <hr>
            </div>
            <div class="col-12 col-md-6">
                <h2 class="community-events-header montserrat-light">Love Our Cats</h2>
                <p class="community-events-text montserrat">
                    Play with, socialize, and love on our kitties who really need some lovin' during these trying times. Call {{path.phone}} or click the link below to sign-up!
                </p>
                <div class="community-events-button-div">
                    <a class="event-button-link" href=""><button class="btn btn-primary community-events-button">Register for Love Our Cats</button></a>
                </div>
            </div>
            <div class="col-12 col-md-6 text-center">
                <img src="assets/img/fliers/love_our_cats.jpg" alt="">
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>