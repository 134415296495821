<app-header></app-header>
<events-banner></events-banner>
<div class="events base-background">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <h1 class="montserrat-light text-center">Come to an AAF event!</h1>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <div #action1 class="action text-center">
                    <h2 class="montserrat-light">Community Events</h2>
                    <p class="montserrat">Check out our community fundraising events! Everyone's invited!</p>
                    <a href="{{path.events}}/{{path.community_events}}"><button class="btn btn-primary montserrat">View Community Events</button></a>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div #action2 class="action text-center">
                    <h2 class="montserrat-light">Volunteer Events</h2>
                    <p class="montserrat">Sign up to become a volunteer or come to our public volunteering events!</p>
                    <a href="{{path.events}}/{{path.volunteer_events}}"><button class="btn btn-primary montserrat">View Volunteer Events</button></a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>