<app-header></app-header>
<app-bread-crumbs title="Employer Match" [crumbs]="['Home', 'Donate', 'Direct Donation']" [href]="['', 'donate', 'donate/direct-donation']"></app-bread-crumbs>
<div class="employer-match base-background">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="employer-match-text montserrat">
                    Would you like to see your donation to AAF doubled or even tripled? Your employeer might have a matching gift program.  Matching gift programs are charitable giving programs set up by corporations in which the company matches donations made by employees to eligible nonprofit organizations.<br><br>
                    Ask your Human Resources department if AAF is on their list of eligible agencies.  If not, let them know will be happy to provide them with all of the information they may require. They can contact us at {{path.email}} or call {{path.phone}}. <br><br>
                    Employees of the following companies can sign up for matching gifts via the link provided: <br><br>
                    Email {{path.email}} to add your company!
                </p>
            </div>
        </div>
        <div class="row justify-content-center text-center">
            <div class="col-12">
                <hr>
            </div>
            <div class="col-12 col-md-4">
                <a href="https://doublethedonation.com/forms/fifth-third.pdf" target="_blank"><p class="montserrat resource-text link">Fifth Third</p></a><br>
            </div>
            <div class="col-12 col-md-4">
                <a href="https://www.gefoundation.com/giving-programs/matching-gifts" target="_blank"><p class="montserrat resource-text link">GE</p></a><br>
            </div>
            <div class="col-12 col-md-4">
                <a href="https://doublethedonation.com/forms/lyondellbasell-matching-gift-form.pdf" target="_blank"><p class="montserrat resource-text link">LyondellBasell</p></a><br>
            </div>
            <div class="col-12 col-md-4">
                <a href="https://www.cybergrants.com/pls/cybergrants/eg_portal.home?x_gm_id=5742" target="_blank"><p class="montserrat resource-text link">PNC</p></a><br>
            </div>
        </div>
        <div class="col-12">
            <h3 class="montserrat-light text-center">We also accept corporate donations! Check with your employer to see if they offer matching gifts</h3>
        </div>
    </div>
</div>
<app-footer></app-footer>