export class SendInBlueEmail {
    constructor(name:string, email:string, message:string) {
        this.name = name;
        this.email = email;
        this.message = message;
    }

    name:string;
    email:string;
    message:string;
}