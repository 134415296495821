export class ContentfulPet {

    constructor(age:string, breed:string, gender:string, name:string, photo:string, size:string, species:string,
        url:string, linkType: string) {
        this.age = age;
        this.breed = breed;
        this.gender = gender;
        this.name = name;
        this.photo = photo;
        this.size = size;
        this.species = species;
        this.url = url;
        this.linkType = linkType;
    }
    age: string;
    breed: string;
    gender: string;
    name: string;
    photo: string;
    size: string;
    species: string;
    url: string;
    linkType: string;
}

export class ContentfulEvent {

    constructor(title:string, description:string, url:string, image_url:string, date:string) {
        this.title = title;
        this.description = description;
        this.url = url;
        this.image_url = image_url;
        this.date = date;
    }

    title:string;
    description:string;
    url:string;
    image_url:string;
    date:string;
}

export class ContentfulPotm {
    constructor(name:string, breed:string, gender:string, age:string, description:string, image:string) {
        this.name = name;
        this.breed = breed;
        this.gender = gender;
        this.age = age;
        this.description = description;
        this.image = image;
    }

    name:string;
    breed:string;
    gender:string;
    age:string;
    description:string;
    image:string;
}

export class ContentfulHours {
    constructor(name:string, hours:string) {
        this.name = name;
        this.hours = hours;
    }

    name:string;
    hours:string;
}

export class ContentfulMember {
    constructor(name:string, title:string, email:string, phoneNumber:string, isBoardMember:boolean, order:number) {
        this.name = name;
        this.title = title;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.isBoardMember = isBoardMember;
        this.order = order;
    }

    name:string;
    title:string;
    email:string;
    phoneNumber:string;
    isBoardMember:boolean;
    order:number;
}