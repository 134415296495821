<landing-potm></landing-potm>
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4">
                <h2 class="montserrat footer-text">Pay Us a Visit</h2>
                <p class="montserrat footer-text">Animal Adoption Foundation
                    2480 Ross Millville Rd
                    Hamilton, OH 45013
                </p>
                <p class="montserrat footer-text">
                    <app-hours></app-hours>
                </p>
            </div>
            <div class="col-12 col-md-4 text-center">
                <h2 class="montserrat footer-text">Contact Us</h2>
                <a href="tel:{{path.phone}}" class="montserrat footer-text link-secondary">
                    {{path.phone}}
                </a>
                <br>
                <a href="mailto:{{path.email}}" class="montserrat footer-text link-secondary">
                    {{path.email}}
                </a>
                <div class="container-fluid">
                    <div class="row justify-content-center social-row">
                        <div class="col-6 col-lg-3 social-col">
                            <a href="{{ path.facebook }}" target="_blank"><img class="social-icon" src="assets/img/icon/facebook-base.png" alt="facebook-logo"></a>
                        </div>
                        <div class="col-6 col-lg-3 social-col">
                            <a href="{{ path.twitter }}" target="_blank"><img class="social-icon"  src="assets/img/icon/twitter-base.png" alt="twitter-logo"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 text-center logo-col align-items-start">
                <img class="logo" src="assets/img/logo.jpg" alt="animal-adoption-foundation-logo">
            </div>
        </div>
    </div>
</div>