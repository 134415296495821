import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as path from '../constants/Path';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  path = path;

  constructor() { }

  ngOnInit(): void {
    
  }

  hideModal() {
    
  }
}
