import { Component, OnInit } from '@angular/core';
import * as path from "../../common/constants/Path";

@Component({
  selector: 'landing-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
