<app-header></app-header>
<app-bread-crumbs title="Wishlist" [crumbs]="['Home', 'Donate']" [href]="['', 'donate']"></app-bread-crumbs>
<div class="wishlist base-background">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="wishlist-header montserrat-light">Purchase an item off our wish list</h2>
                <p class="wishlist-text montserrat">
                    Buy an item from our wish list and we'll use it to help us take better care of our animals! You can send them directly to us through the links below or we are available to accept your donations in person during open hours. You can also drop off donations into the large donation box right outside our front door.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="wishlist-header montserrat-light">Amazon Wish List</h2>
                <p class="wishlist-text montserrat">
                    We keep our Amazon wishlists updated with our greatest needs. Feel free to contribute items from the list that are not purchased from Amazon. 
                </p>
                <a target="_blank" href="{{path.amazon_wishlist}}"><button class="btn btn-primary wishlist-button">View Amazon Wish List</button></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="wishlist-header montserrat-light">Chewy Wish List</h2>
                <p class="wishlist-text montserrat">
                    Order pet food or supplies through our wish list on Chewy.com. 
                </p>
                <a target="_blank" href="{{path.chewy_wishlist}}"><button class="btn btn-primary wishlist-button">View Chewy Wish List</button></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="wishlist-header montserrat-light">Kurunda Bed</h2>
                <p class="wishlist-text montserrat">
                    Give a shelter dog or cat a comfortable bed to sleep on while they wait for their forever home!
                </p>
                <a target="_blank" href="{{path.kurunda_wishlist}}"><button class="btn btn-primary wishlist-button">Donate A Kurunda Bed</button></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="wishlist-header montserrat-light">General Wish List Items</h2>
                <p class="wishlist-text montserrat">
                    Below is a list of general items that we can always use more of. These items can be brought directly to the shelter.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <img class="wishlist-image" src="assets/img/wishlist.jpeg" alt="wishlist">
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>