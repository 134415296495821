import { Component, OnInit } from '@angular/core';
import * as path from '../../common/constants/Path';

@Component({
  selector: 'app-donate-page',
  templateUrl: './donate-page.component.html',
  styleUrls: ['./donate-page.component.scss']
})
export class DonatePageComponent implements OnInit {

  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
