import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-animal-angels',
  templateUrl: './animal-angels.component.html',
  styleUrls: ['./animal-angels.component.scss']
})
export class AnimalAngelsComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
