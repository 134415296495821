<app-header></app-header>
<app-bread-crumbs title="Volunteer Events" [crumbs]="['Home', 'Events']" [href]="['', 'events']"></app-bread-crumbs>
<div class="events base-background">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-12">
                <p class="montserrat">Thank you for your interest in volunteering. You can sign up for any community event below or become an official AAF volunteer by completing the <a target="_blank" class="link" href="{{path.volunteer_application}}">volunteer application</a> and attending one or more levels of <a target="_blank" class="link" href="{{path.volunteer}}/{{path.training}}">orientation</a>.</p>
            </div>
        </div>
        <app-spinner [display]="spinnerDisplay"></app-spinner>
        <events-recurring-error [display]="errorDisplay"></events-recurring-error>
        <div #events class="row align-items-center" *ngFor="let event of eventList">
            <div class="col-12">
                <hr>
            </div>
            <div class="col-12"></div>
            <div class="col-12">
                <h2 class="community-events-header montserrat-light">{{ event.title }}</h2>
                <p class="community-events-text montserrat" [innerHTML]="event.description"></p>
                <div class="community-events-button-div">
                    <a href="{{ event.link }}" target="_blank"><button class="btn btn-primary community-events-button">View Dates and Register</button></a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>