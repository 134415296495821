export var home: string = '';
export var adopt: string = 'adopt';
export var volunteer: string = 'volunteer';
export var donate: string = 'donate';
export var events: string = 'events';

export var doggie_dating: string = 'doggie-dating';
export var sponsor: string = 'sponsor-a-pet';
export var foster: string = 'foster-a-pet';

export var direct_donation: string = 'direct-donation';
export var wishlist: string = 'wishlist';
export var corporate_partners: string = 'corprate-partners';
export var animal_angels: string = 'animal-angels-fund';
export var employer_match: string = 'employer-match';

export var roles: string = 'roles';
export var training: string = 'training';

export var about: string = 'about';
export var links: string = 'links';
export var sponsors: string = 'our-sponsors';
export var members: string = 'members';
export var contact: string = 'contact-us';

export var volunteer_events: string = 'volunteer';
export var community_events: string = 'community';

export var facebook = 'https://www.facebook.com/AnimalAdoptionFoundation';
export var twitter = 'https://twitter.com/AAFPets';
export var email = 'office@aafpets.org';
export var phone = '513-737-7387';
export var eventbrite =
  'https://www.eventbrite.com/o/animal-adoption-foundation-1020263197';

export var adoption_application =
  'https://us06d.sheltermanager.com/service?account=gz1050&method=online_form_html&formid=2';
export var volunteer_application =
  'https://app.betterimpact.com/Application?OrganizationGuid=a4135096-cb18-49b1-823d-684e0229b325&ApplicationFormNumber=1';
export var donation_application =
  'https://forms.donorsnap.com/form?id=672972b0-ab11-4563-a272-0aca85420afe&fbclid=IwAR3g9kjyer9Fu_tr-YdVcLVc3hbQq1eB-oV_p1cKo12rmi0sEUPdFeEx8pQ';
export var foster_application =
  'https://service.sheltermanager.com/asmservice?account=aafpets&method=online_form_html&formid=4';
export var sponsor_application =
  'https://forms.donorsnap.com/form?id=5cc336f4-793a-456a-9442-8618ae8c9ccd';

export var amazon_partner = 'http://smile.amazon.com/ch/31-1378848';
export var barkbox_partner =
  'https://barkbox.com/?utm_keyword=shelter_referral&url_coupon=BBX179VK';
export var kroger_partner =
  'https://www.kroger.com/i/community/community-rewards';

export var chewy_wishlist =
  'https://www.chewy.com/g/animal-adoption-foundation_b76050233#wish-list';
export var kurunda_wishlist = 'http://kuranda.com/donate/3081';
export var amazon_wishlist =
  'https://www.amazon.com/hz/wishlist/ls/KNT4P4PFNVMB?ref_=wl_dp_view_your_list&fbclid=IwAR3Hf-mWyyQlR8qfcYAmG8mnuSvpk9pIoL-499ZjKAQPCJwQtHX1FmSBQQI';

export var training_1 =
  'https://www.eventbrite.com/e/level-1-new-volunteer-orientation-registration-28050038420';
export var training_2 =
  'https://www.eventbrite.com/e/level-2-dog-care-orientation-registration-28259253187';
export var training_3 =
  'https://www.eventbrite.com/e/level-3-advanced-dog-care-orientation-registration-28261809834';
export var training_4 =
  'https://www.eventbrite.com/e/level-4-mutt-mentoring-tickets-60197371960';
