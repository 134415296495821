import { Component, OnInit } from '@angular/core';
import * as path from "../../common/constants/Path";

@Component({
  selector: 'landing-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
