import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContentfulService } from 'src/app/service/contentful/contentful.service';

@Component({
  selector: 'landing-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @ViewChild('alert') alert:ElementRef;
  @ViewChild('alertLink') alertLink:ElementRef;
  @ViewChild('alertLinkColumn') alertLinkColumn:ElementRef;
  @ViewChild('alertImage') alertImage:ElementRef;
  @ViewChild('alertImageColumn') alertImageColumn:ElementRef;
  spinnerDisplay: boolean = true;
  errorDisplay: boolean = false;  
  constructor(private contentfulService:ContentfulService, private renderer:Renderer2) { }

  ngOnInit(): void {
    this.contentfulService.getNews()
      .then(res=>{
        if(!!res[0]?.fields?.body) {
          this.alert.nativeElement.innerHTML=res[0].fields.body;
        }
        if(!!res[0]?.fields?.link) {
          this.renderer.setStyle(this.alertLinkColumn.nativeElement, 'display', 'initial');
          this.alertLink.nativeElement.href=res[0].fields.link;
        }
        if(!!res[0]?.fields?.newsImage) {
          this.renderer.setStyle(this.alertImageColumn.nativeElement, 'display', 'initial');
          this.alertImage.nativeElement.src = res[0].fields.newsImage.fields.file.url;
        }
        this.spinnerDisplay=false
      })
      .catch(error=>{
        this.errorDisplay=true;
        this.spinnerDisplay=false;
      });
  }

}
