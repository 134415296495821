<app-header></app-header>
<donate-banner></donate-banner>
<div class="donation-top base-background">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="montserrat-light donation-h1">Your Donations Make a Difference</h1>
                <p class="montserrat">The AAF functions solely on private donations, with no government funding. 
                    We simply can't do it without your support! </p>
                <a target="_blank" href="{{path.donation_application}}"><button class="btn btn-primary montserrat">Donate Online</button></a>
            </div>
        </div>
    </div>
</div>
<div class="donation-options">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <h3 class="montserrat donation-h3">Learn about all the ways you can give to help animals in need</h3>
            </div>
        </div>
        <div class="row text-center options-row">
            <div class="col-12 col-md-4">
                <div class="option">
                    <p class="montserrat options-p">Give money directly to the Animal Adoption Foundation</p>
                    <a href="{{path.donate}}/{{path.direct_donation}}"><button class="btn btn-secondary montserrat">Direct Donation</button></a>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="option">
                    <p class="montserrat options-p">Make a purchase from one of our partner companies</p>
                    <a href="{{path.donate}}/{{path.corporate_partners}}"><button class="btn btn-secondary montserrat">Corporate Partners</button></a>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="option">
                    <p class="montserrat options-p">Purchase an item off our wish list</p>
                    <a href="{{path.donate}}/{{path.wishlist}}"><button class="btn btn-secondary montserrat">Wish List</button></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="donation-bottom base-background">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <p class="montserrat-light bottom-text">Your donation may be tax deductible. </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>