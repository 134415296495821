import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/service/contentful/contentful.service';
import * as path from '../../common/constants/Path';

@Component({
  selector: 'landing-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }
}
