<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active slide-1">
          <div class="container-fluid carousel-container">
              <div class="carousel-content">
                <div class="row justify-content-center carousel-text-row">
                    <div class="col-12">
                      <h1 class="carousel-text montserrat-bold">You can't buy love, <br>but you can rescue it.</h1>
                    </div>
                </div>
                <div class="row justify-content-center carousel-button-row">
                    <div class="col-12">
                      <a href="{{path.adopt}}"><button type="button" class="btn btn-primary carousel-button montserrat">Adopt a Pet</button></a>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <div class="carousel-item slide-2">
        <div class="container-fluid carousel-container">
            <div class="carousel-content">
              <div class="row justify-content-center carousel-text-row">
                  <div class="col-12">
                    <h1 class="carousel-text montserrat-bold">No one can do everything, <br>but everyone can do something.</h1>
                  </div>
              </div>
              <div class="row justify-content-center carousel-button-row">
                  <div class="col-12">
                    <a href="{{path.volunteer}}"><button type="button" class="btn btn-primary carousel-button montserrat">Become a Volunteer</button></a>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <div class="carousel-item slide-3">
        <div class="container-fluid carousel-container">
            <div class="carousel-content">
              <div class="row justify-content-center carousel-text-row">
                  <div class="col-12">
                    <h1 class="carousel-text montserrat-bold">We make a living by what we make, <br>but we make a life by what we give.</h1>
                  </div>
              </div>
              <div class="row justify-content-center carousel-button-row">
                  <div class="col-12">
                    <a href="{{path.donate}}"><button type="button" class="btn btn-primary carousel-button montserrat">Donate to Save a Life</button></a>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>