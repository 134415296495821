import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as path from "../../common/constants/Path";
import { ContentfulService } from 'src/app/service/contentful/contentful.service';
import { ContentfulPet } from 'src/app/model/contentful.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'adopt-widget',
  templateUrl: './adopt-widget.component.html',
  styleUrls: ['./adopt-widget.component.scss']
})
export class AdoptWidgetComponent implements OnInit {
  @ViewChild('animal') animal:ElementRef;
  @ViewChild('gender') gender:ElementRef;
  @ViewChild('age') age:ElementRef;
  @ViewChild('size') size:ElementRef;
  @ViewChild('breed') breed:ElementRef;
  @ViewChild('noPetsMessage') noPetsMessage:ElementRef;
  @ViewChild('courtesyModal') courtesyModal:ElementRef;
  @ViewChild('courtesyModalButton') courtesyModalButton:ElementRef;
  spinnerDisplay: boolean = true;  
  errorDisplay: boolean = false;
  path = path;
  animalList:ContentfulPet[] = [];
  params:PetParams;
  showCourtesyModal:boolean = false;
  courtesyModalNumber:string = "###-###-####"

  constructor(private contentfulService:ContentfulService, private route: ActivatedRoute, private renderer:Renderer2) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.params = new PetParams(params.age, params.animal, params.breed, params.gender, params.size);
      }
    );

    this.contentfulService.getPets().then(res=> {
      for(let pet of res) {
        this.animalList.push(new ContentfulPet(
          pet.fields.age, pet.fields.breed, pet.fields.gender, pet.fields.name, pet.fields.photoUrl, 
          pet.fields.size, pet.fields.species, pet.fields.url, pet.fields.linkType));
      }
      this.errorDisplay=false;
      this.spinnerDisplay = false;
    }).catch(error => {
        this.spinnerDisplay=false;
        this.errorDisplay=true;
      }).finally(()=> {
        this.filterAnimals();
        if(this.animalList.length==0) {
          this.renderer.setStyle(this.noPetsMessage.nativeElement, 'display', 'block');
        }
        this.setValueOfFields();
      }
    )
  }

  filterAnimals() {
    for(let index = 0; index<this.animalList.length; index++) {
      if(this.removePetCheck(this.animalList[index])) {
        this.animalList.splice(index, 1);
        index--;
      }
    }
  }

  removePetCheck(pet:ContentfulPet) {
    if(this.areAnyParamsActive() && 
      (this.params.age!='all' && pet?.age.toUpperCase() != this.params?.age.toUpperCase() || 
      this.params.animal!='all' && pet?.species.toUpperCase() != this.params?.animal.toUpperCase() || 
      this.params.breed!='' && !pet?.breed.toUpperCase().includes(this.params?.breed.toUpperCase()) || 
      this.params.gender!='all' && pet?.gender.toUpperCase() != this.params?.gender.toUpperCase() || 
      this.params.size!='all' && pet?.size.toUpperCase() != this.params?.size.toUpperCase())) {
        return true;
      }
    return false;
  }

  areAnyParamsActive():boolean {
    return (this.params.age != undefined || this.params.animal != undefined || this.params.breed != undefined || this.params.gender != undefined || this.params.size != undefined);
  }

  setValueOfFields():void {
    if(this.areAnyParamsActive()) {
      this.age.nativeElement.value = this.params.age;
      this.animal.nativeElement.value = this.params.animal;
      this.breed.nativeElement.value = this.params.breed;
      this.gender.nativeElement.value = this.params.gender;
      this.size.nativeElement.value = this.params.size;
    }
  }

  onPetClick(animal:ContentfulPet) {
    
    if(animal.linkType=='phone') {
      this.courtesyModalNumber = animal.url;
      this.courtesyModalButton.nativeElement.click();
    } else {
      window.open(animal.url, "_blank");
    } 
  }
}

class PetParams {
  age:string;
  animal:string;
  breed:string;
  gender:string;
  size:string;

  constructor(age:string, animal:string, breed:string, gender:string, size:string) {
    this.age = age;
    this.animal = animal;
    this.breed = breed;
    this.gender = gender;
    this.size = size;
  }
}