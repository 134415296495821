import { Component, OnInit } from '@angular/core';
import * as path from '../../common/constants/Path';

@Component({
  selector: 'adopt-links',
  templateUrl: './adopt-links.component.html',
  styleUrls: ['./adopt-links.component.scss']
})
export class AdoptLinksComponent implements OnInit {
  path = path;

  constructor() { }

  ngOnInit(): void {
  }

}
