import { rendererTypeName } from '@angular/compiler';
import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {

  @Input() title: string;
  @Input() crumbs: string[];
  @Input() href: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
