<div class="bread-crumbs base-background">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="single-crumb" *ngFor="let crumb of crumbs; let i = index">
                    <a href="{{href[i]}}" class="montserrat bread-crumbs-text">{{crumb}}</a> 
                    <span *ngIf="(i != crumbs.length-1)" class="montserrat bread-crumbs-text">&nbsp;>&nbsp;</span>
                </div>
            </div>
            <div class="col-12">
                <h1 class="montserrat page-title">{{title}}</h1>
            </div>
            
        </div>
    </div>
</div>