<app-header></app-header>
<about-banner></about-banner>
<div class="about-page base-background">
    <div class="container">
        <div class="row text-center about-text-row">
            <div class="col-12">
                <p class="montserrat">
                    The Animal Adoption Foundation is a non-profit no-kill shelter for dogs and cats that have been abused or abandoned. AAF provides a safe and humane environment for dogs and cats that are waiting to be adopted. Our caring volunteers and generous, loving donors enable us to provide a safe and happy haven for our special friends. The shelter currently houses approximately 85 cats and dogs.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="mission-row">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <h2 class="montserrat mission-text">Our Mission</h2>
                <p class="montserrat mission-text">A proactive, no-kill animal rescue focused on the well-being of dogs and cats in need.</p>
            </div>
        </div>
    </div>
</div>

<div class="actions-row base-background actions-row">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <h1 class="montserrat-light actions-header">Learn About Who We Are!</h1>
            </div>
        </div>
        <div class="row text-center justify-content-center">
            <div class="col-12 col-md-6">
                <div #action1 class="action text">
                    <p class="montserrat actions-p">Find out more about our members!</p>
                    <a href="{{path.about}}/{{path.members}}"><button class="btn btn-primary montserrat">Our Members</button></a>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div #action2 class="action">
                    <p class="montserrat actions-p">See our partners who enable us to do what we do!</p>
                    <a href="{{path.about}}/{{path.sponsors}}"><button class="btn btn-primary montserrat">Our Sponsors</button></a>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div #action3 class="action">
                    <p class="montserrat actions-p">Learn the best way to contact us!</p>
                    <a href="{{path.about}}/{{path.contact}}"><button class="btn btn-primary montserrat">Contact Us</button></a>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div #action4 class="action">
                    <p class="montserrat actions-p">Check out our list of external resources!</p>
                    <a href="{{path.about}}/{{path.links}}"><button class="btn btn-primary montserrat">External Resources</button></a>
                </div>
            </div>


        </div>
    </div>
</div>
<app-footer></app-footer>