import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { BannerComponent } from './common/banner/banner.component';
import { CarouselComponent } from './landing-components/carousel/carousel.component';
import { InfoComponent } from './landing-components/info/info.component';
import { ActionsComponent } from './landing-components/actions/actions.component';
import { FooterComponent } from './common/footer/footer.component';
import { EventsComponent } from './landing-components/events/events.component';
import { PotmComponent } from './landing-components/potm/potm.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { AdoptPageComponent } from './pages/adopt-page/adopt-page.component';
import { VolunteerPageComponent } from './pages/volunteer-page/volunteer-page.component';
import { DonatePageComponent } from './pages/donate-page/donate-page.component';
import { AdoptLinksComponent } from './adopt-components/adopt-links/adopt-links.component';
import { AdoptWidgetComponent } from './adopt-components/adopt-widget/adopt-widget.component';
import { AdoptBannerComponent } from './adopt-components/adopt-banner/adopt-banner.component';
import { DonateBannerComponent } from './donate-components/donate-banner/donate-banner.component';
import { VolunteerBannerComponent } from './volunteer-components/volunteer-banner/volunteer-banner.component';
import { EventsBannerComponent } from './events-components/events-banner/events-banner.component';
import { BreadCrumbsComponent } from './common/bread-crumbs/bread-crumbs.component';
import { DoggieDatingComponent } from './pages/adopt-page/doggie-dating/doggie-dating.component';
import { SponsorComponent } from './pages/adopt-page/sponsor/sponsor.component';
import { FosterComponent } from './pages/adopt-page/foster/foster.component';
import { RolesComponent } from './pages/volunteer-page/roles/roles.component';
import { TrainingComponent } from './pages/volunteer-page/training/training.component';
import { VolunteerEventsComponent } from './pages/volunteer-page/volunteer-events/volunteer-events.component';
import { DirectDonationComponent } from './pages/donate-page/direct-donation/direct-donation.component';
import { CorporatePartnersComponent } from './pages/donate-page/corporate-partners/corporate-partners.component';
import { WishlistComponent } from './pages/donate-page/wishlist/wishlist.component';
import { AnimalAngelsComponent } from './pages/donate-page/animal-angels/animal-angels.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AboutBannerComponent } from './about-components/about-banner/about-banner.component';
import { ContactUsComponent } from './pages/about-page/contact-us/contact-us.component';
import { LinksComponent } from './pages/about-page/links/links.component';
import { SponsorsComponent } from './pages/about-page/sponsors/sponsors.component';
import { MembersComponent } from './pages/about-page/members/members.component';
import { NewsComponent } from './landing-components/news/news.component';
import { RecurringEventsComponent } from './pages/events-page/recurring-events/recurring-events.component';
import { AnnualEventsComponent } from './pages/events-page/annual-events/annual-events.component';
import { EmployerMatchComponent } from './pages/donate-page/employer-match/employer-match.component';
import { ContentfulService } from './service/contentful/contentful.service';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { ErrorMessageComponent } from './common/error-message/error-message.component';
import { PetfinderService } from './service/petfinder/petfinder.service';
import { EventbriteService } from './service/eventbrite/eventbrite.service';
import { AdoptErrorComponent } from './adopt-components/adopt-error/adopt-error.component';
import { RecurringErrorComponent } from './events-components/recurring-error/recurring-error.component';
import { AnnualErrorComponent } from './events-components/annual-error/annual-error.component';
import { HoursComponent } from './common/hours/hours.component';
import { MemberComponent } from './common/member/member.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingPageComponent,
    BannerComponent,
    CarouselComponent,
    InfoComponent,
    ActionsComponent,
    FooterComponent,
    EventsComponent,
    PotmComponent,
    EventsPageComponent,
    AdoptPageComponent,
    VolunteerPageComponent,
    DonatePageComponent,
    AdoptLinksComponent,
    AdoptWidgetComponent,
    AdoptBannerComponent,
    DonateBannerComponent,
    VolunteerBannerComponent,
    EventsBannerComponent,
    BreadCrumbsComponent,
    DoggieDatingComponent,
    SponsorComponent,
    FosterComponent,
    RolesComponent,
    TrainingComponent,
    VolunteerEventsComponent,
    DirectDonationComponent,
    CorporatePartnersComponent,
    WishlistComponent,
    AnimalAngelsComponent,
    AboutPageComponent,
    AboutBannerComponent,
    ContactUsComponent,
    LinksComponent,
    SponsorsComponent,
    MembersComponent,
    NewsComponent,
    RecurringEventsComponent,
    AnnualEventsComponent,
    EmployerMatchComponent,
    SpinnerComponent,
    ErrorMessageComponent,
    AdoptErrorComponent,
    RecurringErrorComponent,
    AnnualErrorComponent,
    HoursComponent,
    MemberComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    ContentfulService,
    PetfinderService,
    EventbriteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
