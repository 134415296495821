<div class="info base-background">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-12">
                <app-spinner [display]="spinnerDisplay"></app-spinner>
                <app-error-message [display]="errorDisplay"></app-error-message>  
                <p #alert class="montserrat text-center small-text alert"></p>
            </div>
            <div class="col-12 alert-link-column" #alertLinkColumn>
                <a #alertLink target="_blank">
                    <button class="btn btn-primary alert-link">Learn More</button>
                </a>
            </div>
            <div class="col-12 alert-image-column" #alertImageColumn>
                <img #alertImage class="alert-image" alt="news alert image">
            </div>
            <div class="col-12">
                <p class="small-text montserrat">The Animal Adoption Foundation is a non-profit, no-kill shelter for dogs and cats that have been abused, abandoned, or found as strays. AAF provides a safe and humane environment for dogs and cats that are waiting to be adopted. Our caring volunteers and generous, loving donors enable us to provide a safe and happy haven for our special friends. The shelter currently houses approximately 85 cats and dogs.
                </p>
            </div>
        </div>
    </div>
</div>