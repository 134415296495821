import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-adopt-error',
  templateUrl: './adopt-error.component.html',
  styleUrls: ['./adopt-error.component.scss']
})
export class AdoptErrorComponent implements OnChanges {
  @Input() display: boolean;
  @ViewChild('error') error:ElementRef;

  constructor(private renderer:Renderer2) { }

  ngOnChanges(): void {
    this.setErrorDisplay();
  }

  setErrorDisplay() {
    if(!!this.error && !this.display) {
      this.renderer.setStyle(this.error.nativeElement, 'display', 'none');
    }
    else if(!!this.error && this.display) {
      this.renderer.setStyle(this.error.nativeElement, 'display', 'block');
    }
  }
}
