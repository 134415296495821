export const CONTENTFUL = {
    space: 'gb4veknims1n',
    accessToken:
      'ey8ltEO5ITLLbonWk4YmAeUkGcr6iGe_M2iH-NZ7dT4',
    contentTypeIds: {
      newsAlert: 'newsAlert', pet: 'pet', event: 'event', petsOfTheMonth: 'petsOfTheMonth', hours: 'availableHours', members: 'member'
    },
  };

  export const EVENTBRITE = {
    authToken: "3XIPGT7MDWALI6UNKZOY",
    org: "9787090399"
  }

  export const SENDINBLUE = {
    key: "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"
  }