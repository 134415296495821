<div class="actions">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-4 text-center">
                <h2 class="action-header montserrat">Adopt</h2>
                <a href="{{path.adopt}}" class="action-link">
                    <div #actionDiv1 class="action-div base-background">
                        <img class="action-icon" src="assets/img/icon/home.jpg" alt="adopt-link">
                    </div>
                </a>
                <p class="action-text montserrat">Discover all the ways you can provide hands-on care for an animal in need!</p>
                <a href="{{path.adopt}}">
                    <button class="btn btn-secondary action-button">Adopt</button>
                </a>
            </div>
            <div class="col-12 col-md-4 text-center">
                <h2 class="action-header montserrat">Volunteer</h2>
                <a href="{{path.volunteer}}" class="action-link">
                    <div #actionDiv2 class="action-div base-background">
                        <img class="action-icon" src="assets/img/icon/heart.jpg" alt="heart-link">
                    </div>
                </a>
                <p class="action-text montserrat">Begin your journey of giving back by filling out a volunteer application today!</p>
                <a href="{{path.volunteer}}">
                    <button class="btn btn-secondary action-button">Volunteer</button>
                </a>
            </div>
            <div class="col-12 col-md-4 text-center">
                <h2 class="action-header montserrat">Donate</h2>
                <a href="{{path.donate}}" class="action-link">
                    <div #actionDiv3 class="action-div base-background">
                        <img class="action-icon" src="assets/img/icon/gift.jpg" alt="donate-link">
                    </div>
                </a>
                <p class="action-text montserrat">Provide financial support through one of our many donation channels!</p>
                <a href="{{path.donate}}">
                    <button class="btn btn-secondary action-button">Donate</button>
                </a>
            </div>
        </div>
    </div>
</div>