import { Component, OnInit } from '@angular/core';
import { ContentfulEvent, EventBriteEventResponse } from 'src/app/model/event.model';
import { ContentfulService } from 'src/app/service/contentful/contentful.service';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-annual-events',
  templateUrl: './annual-events.component.html',
  styleUrls: ['./annual-events.component.scss']
})
export class AnnualEventsComponent implements OnInit {
  spinnerDisplay: boolean = true;  
  errorDisplay: boolean = false;
  path = path;
  eventList:ContentfulEvent[] = [];
  days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

  constructor(private contentfulService:ContentfulService) { }

  ngOnInit(): void {
    this.contentfulService.getEvents().then(res=> {
      for(let event of res) {
        this.eventList.push(new ContentfulEvent(
          event.fields.title, event.fields.description, event.fields.url, event.fields.image.fields.file.url, this.convertDateToString(event.fields.date)));
      }
      console.log(this.eventList);
      this.errorDisplay=false;
      this.spinnerDisplay = false;
    }).catch(error => {
        this.spinnerDisplay=false;
        this.errorDisplay=true;
      })
  }

  convertDateToString(date:string):string {
    let dateObject:Date = new Date(date);
    let timeSuffix = dateObject.getHours()>=12 ? "PM" : "AM";
    let month = this.months[dateObject.getMonth()];
    let day = dateObject.getDate();
    let year = dateObject.getFullYear();
    let hours = dateObject.getHours()%12;
    let minutes = dateObject.getMinutes() < 10 ? '0'+dateObject.getMinutes() : dateObject.getMinutes();
    return this.days[dateObject.getDay()] + ", " + month + " " + day + ", " + year + " at " + hours + ":" + minutes + " " + timeSuffix;
  }

}
