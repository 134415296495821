<app-header></app-header>
<app-bread-crumbs title="Sponsor A Pet" [crumbs]="['Home', 'Adopt']" [href]="['', 'adopt']"></app-bread-crumbs>
<div class="sponsor base-background">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <p class="montserrat">
                    There are more homeless animals than available adopters. You can help a cat or dog until a forever home can be found. Sponsors like you provide food, shelter, medical care, and comforts for pets in need.<br><br>
                    Your sponsorship donation may be tax deductible. AAF is a nonprofit 501(c)(3) no-kill animal shelter located in Butler County (EIN 31-1378848). <br><br>
                    Set up your sponsorship online, in person when you visit the shelter, over the phone to {{path.phone}}, or by check (made to AAF, mailed to 2480 Ross Millville Road, Hamilton, OH 45013).<br><br>
                    Sponsored pets may get a little more notice from potential adopters. Whenever possible, we recognize sponsors on the pet's information sign and in our newsletters. Will you become a sponsor and help a pet in need?<br><br>
                </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <a target="_blank" href="{{path.sponsor_application}}"><button class="btn btn-primary montserrat">Pet Sponsorship Form</button></a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>