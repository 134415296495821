import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-corporate-partners',
  templateUrl: './corporate-partners.component.html',
  styleUrls: ['./corporate-partners.component.scss']
})
export class CorporatePartnersComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
