import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnChanges {
  @Input() name:string;
  @Input() title:string;
  @Input() phoneNumber:string;
  @Input() email:string;
  @Input() number:string;
  firstName:string = "";
  constructor() { }

  ngOnChanges(): void {
    this.firstName = this.name.split(" ")[0];
  }

}
