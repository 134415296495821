<div class="adopt-links-top">
    <div class="container">
        <div class="row text-center adopt-links-top-row">
            <div class="col-12">
                <h2 class="adopt-links-top-text montserrat">Begin the adoption process today!</h2>
            </div>
        </div>
        <div class="row text-center adopt-links-top-row">
            <div class="col-12">
                <p class="adopt-links-top-text montserrat">Adoptions are currently appointment only. Once your application has been qualified, 
                    you will be notified and we will schedule an appointment.</p>
            </div>
            <div class="col-12">
                <a target="_blank" href="{{path.adoption_application}}"><button class="btn btn-secondary montserrat">Online Application</button></a>
            </div>
        </div>
        <div class="row text-center adopt-links-top-row">
            <div class="col-12">
                <p class="adopt-links-top-text montserrat">Check out the animals currently available for adoption.</p>
            </div>
            <div class="col-12">
                <a href="{{path.adopt}}/#availability"><button class="btn btn-secondary montserrat">Available Pets</button></a>
            </div>
        </div>
    </div>
</div>
<div class="adopt-links-bottom base-background">
    <div class="container">
        <div class="row text-center adopt-links-bottom-row">
            <div class="col-12">
                <h3 class="adopt-links-bottom-text montserrat">
                    Can't adopt but want to provide care for an animal in need? 
                    Here's are some other services we provide!
                </h3>
            </div>
        </div>
        <div class="row text-center adopt-links-bottom-row">
            <div class="col-12">
                <p class="adopt-links-bottom-text montserrat">Bring a dog home for an afternoon or evening with
                    our Doggie Dating program!</p>
            </div>
            <div class="col-12">
                <a href="{{path.adopt}}/{{path.doggie_dating}}"><button class="btn btn-primary montserrat">Doggie Dating</button></a>
            </div>
        </div>
        <div class="row text-center adopt-links-bottom-row">
            <div class="col-12">
                <p class="adopt-links-bottom-text montserrat">Want to host an animal for longer than a day but unable
                    to make a long-term commitment?</p>
            </div>
            <div class="col-12">
                <a href="{{path.adopt}}/{{path.foster}}"><button class="btn btn-primary montserrat">Foster A Pet</button></a>
            </div>
        </div>
        <div class="row text-center adopt-links-bottom-row">
            <div class="col-12">
                <p class="adopt-links-bottom-text montserrat">Provide financial and emotional support for an animal 
                    until they find their forever home! </p>
            </div>
            <div class="col-12">
                <a href="{{path.adopt}}/{{path.sponsor}}"><button class="btn btn-primary montserrat">Sponsor A Pet</button></a>
            </div>
        </div>
    </div>
</div>