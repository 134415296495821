<div class="header base-background">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-between">
            <div class="col-9 col-lg-5">
                <h1 class="montserrat medium-text main-header"><a href="" class="link">Animal Adoption Foundation</a></h1>
            </div>
            <div class="col-3 col-lg-7">
                <ul class="navbar desktop-flex">
                    <li class="header-li">
                        <a href={{path.adopt}} class="montserrat link small-text">Adopt</a>
                    </li>
                    <li class="header-li">
                        <a href={{path.volunteer}} class="montserrat link small-text">Volunteer</a>
                    </li>
                    <li class="header-li">
                        <a href={{path.donate}} class="montserrat link small-text">Donate</a>
                    </li>
                    <li class="header-li">
                        <a href={{path.events}} class="montserrat link small-text">Events</a>
                    </li>
                    <li class="header-li">
                        <a href={{path.about}} class="montserrat link small-text">About</a>
                    </li>
                    <li class="header-li">
                        <img data-toggle="modal" data-target="#modal" src="assets/img/icon/hamburger.jpg" alt="Open site map" class="hamburger">
                    </li>
                </ul>
                <img data-toggle="modal" data-target="#modal" src="assets/img/icon/hamburger.jpg" alt="Open site map" class="hamburger hamburger-mobile mobile">
            </div>
        </div>
    </div>
</div>

<!-- Button trigger modal -->
  
  <!-- Modal -->
  <div class="modal fade" id="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
            <div class="site-map base-background">
                <div class="container">
                    <div class="row site-map-header align-items-center">
                        <div class="col-8 col-md-6">
                            <h1 class="desktop site-map-header-text">
                                <a href="" class="montserrat link header-text">Animal Adoption Foundation</a>
                            </h1>
                            <h1 class="mobile site-map-header-text">
                                <p class="montserrat quick-links-text quick-links-text-mobile header-text">Quick Links</p>
                            </h1>
                        </div>
                        <div class="col-2 col-md-4">
                            <h2 class="desktop site-map-header-subtitle">
                                <p class="montserrat quick-links-text header-text">Quick Links</p>
                            </h2>
                        </div>
                        <div class="col-2 d-flex justify-content-end">
                            <img src="assets/img/icon/x.jpg" alt="Close site map" data-dismiss="modal" aria-label="Close" class="x icon">
                        </div>
                    </div>
                    <div class="row site-map-body">
                        <div class="col-12">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-4 col-12 site-map-row">
                                        <h1 class="main-link site-map-header-text">
                                            <a href="{{path.adopt}}" class="montserrat link">Adopt</a>
                                        </h1>
                                        <ul class="sub-links">
                                            <li class="site-map-li">
                                                <a href="{{path.adopt}}#availability" class="montserrat link sub-link-text" onclick="location.reload()">Adoptable Pets</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.adopt}}/{{path.foster}}" class="montserrat link sub-link-text">Foster</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.adopt}}/{{path.sponsor}}" class="montserrat link sub-link-text">Sponsor</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.adopt}}/{{path.doggie_dating}}" class="montserrat link sub-link-text">Doggie Dating</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-12 site-map-row">
                                        <h1 class="main-link site-map-header-text">
                                            <a href="{{path.volunteer}}" class="montserrat link">Volunteer</a>
                                        </h1>
                                        <ul class="sub-links">
                                            <li class="site-map-li">
                                                <a href="{{path.volunteer}}/{{path.roles}}" class="montserrat link sub-link-text">Roles</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.volunteer}}/{{path.training}}" class="montserrat link sub-link-text">Training</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-12 site-map-row">
                                        <h1 class="main-link site-map-header-text">
                                            <a href="{{path.donate}}" class="montserrat link">Donate</a>
                                        </h1>
                                        <ul class="sub-links">
                                            <li class="site-map-li">
                                                <a href="{{path.donate}}/{{path.direct_donation}}" class="montserrat link sub-link-text">Direct Donation</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.donate}}/{{path.corporate_partners}}" class="montserrat link sub-link-text">Corporate Partners</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.donate}}/{{path.wishlist}}" class="montserrat link sub-link-text">Wish List</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.donate}}/{{path.animal_angels}}" class="montserrat link sub-link-text">Animal Angels Fund</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.donate}}/{{path.employer_match}}" class="montserrat link sub-link-text">Employer Match</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-12 site-map-row">
                                        <h1 class="main-link site-map-header-text">
                                            <a href="{{path.events}}" class="montserrat link">Events</a>
                                        </h1>
                                        <ul class="sub-links">
                                            <li class="site-map-li">
                                                <a href="{{path.events}}/{{path.volunteer_events}}" class="montserrat link sub-link-text">Volunteer Events</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.events}}/{{path.community_events}}" class="montserrat link sub-link-text">Community Events</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-12 site-map-row">
                                        <h1 class="main-link site-map-header-text">
                                            <a href="{{path.about}}" class="montserrat link">About Us</a>
                                        </h1>
                                        <ul class="sub-links">
                                            <li class="site-map-li">
                                                <a href="{{path.about}}/{{path.contact}}" class="montserrat link sub-link-text">Contact Us</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.about}}/{{path.members}}" class="montserrat link sub-link-text">Our Members</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.about}}/{{path.sponsors}}" class="montserrat link sub-link-text">Our Sponsors</a>
                                            </li>
                                            <li class="site-map-li">
                                                <a href="{{path.about}}/{{path.links}}" class="montserrat link sub-link-text">Resource Links</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-12 site-map-row">
                                        <div class="container-fluid">
                                            <div class="row justify-content-center site-map-right">
                                                <div class="col-12 site-map-right-col">
                                                    <img src="assets/img/logo.jpg" alt="animal adoption foundation logo" class="logo">
                                                </div>
                                            </div>
                                            <div class="row justify-content-center site-map-right">
                                                <div class="col-12 site-map-right-col">
                                                    <a href="tel:{{path.phone}}" class="montserrat link site-map-right-text">513-737-PETS(7387)</a>
                                                </div>
                                            </div>
                                            <div class="row justify-content-center site-map-right">
                                                <div class="col-12 site-map-right-col">
                                                    <a href="mailto:{{path.email}}" class="montserrat link site-map-right-text">{{path.email}}</a>
                                                </div>
                                            </div>
                                            <div class="row site-map-right social-row">
                                                <div class="col-6 col-lg-4 social-col">
                                                    <a href="{{ path.facebook }}" target="_blank"><img class="social" src="assets/img/icon/facebook-primary.png" alt="facebook-logo"></a>
                                                </div>
                                                <div class="col-6 col-lg-4 social-col">
                                                    <a href="{{ path.twitter }}" target="_blank"><img class="social"  src="assets/img/icon/twitter-primary.png" alt="twitter-logo"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>