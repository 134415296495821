import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as path from 'src/app/common/constants/Path';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { AdoptPageComponent } from './pages/adopt-page/adopt-page.component';
import { DonatePageComponent } from './pages/donate-page/donate-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { VolunteerPageComponent } from './pages/volunteer-page/volunteer-page.component';
import { DoggieDatingComponent } from './pages/adopt-page/doggie-dating/doggie-dating.component';
import { SponsorComponent } from './pages/adopt-page/sponsor/sponsor.component';
import { FosterComponent } from './pages/adopt-page/foster/foster.component';
import { DirectDonationComponent } from './pages/donate-page/direct-donation/direct-donation.component';
import { CorporatePartnersComponent } from './pages/donate-page/corporate-partners/corporate-partners.component';
import { WishlistComponent } from './pages/donate-page/wishlist/wishlist.component';
import { AnimalAngelsComponent } from './pages/donate-page/animal-angels/animal-angels.component';
import { RolesComponent } from './pages/volunteer-page/roles/roles.component';
import { TrainingComponent } from './pages/volunteer-page/training/training.component';
import { VolunteerEventsComponent } from './pages/volunteer-page/volunteer-events/volunteer-events.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { LinksComponent } from './pages/about-page/links/links.component';
import { SponsorsComponent } from './pages/about-page/sponsors/sponsors.component';
import { MembersComponent } from './pages/about-page/members/members.component';
import { ContactUsComponent } from './pages/about-page/contact-us/contact-us.component';
import { AnnualEventsComponent } from './pages/events-page/annual-events/annual-events.component';
import { RecurringEventsComponent } from './pages/events-page/recurring-events/recurring-events.component';
import { EmployerMatchComponent } from './pages/donate-page/employer-match/employer-match.component';

const routes: Routes = [
  {path:path.home, component: LandingPageComponent},
  {path:path.adopt, component: AdoptPageComponent},
  {path:path.volunteer, component: VolunteerPageComponent},
  {path:path.donate, component: DonatePageComponent},
  {path:path.events, component: EventsPageComponent},
  {path:path.about, component: AboutPageComponent},

  {path:path.adopt+"/"+path.doggie_dating, component: DoggieDatingComponent},
  {path:path.adopt+"/"+path.sponsor, component: SponsorComponent},
  {path:path.adopt+"/"+path.foster, component: FosterComponent},

  {path:path.donate+"/"+path.direct_donation, component: DirectDonationComponent},
  {path:path.donate+"/"+path.corporate_partners, component: CorporatePartnersComponent},
  {path:path.donate+"/"+path.wishlist, component: WishlistComponent},
  {path:path.donate+"/"+path.animal_angels, component: AnimalAngelsComponent},
  {path:path.donate+"/"+path.employer_match, component: EmployerMatchComponent},

  {path:path.volunteer+"/"+path.roles, component: RolesComponent},
  {path:path.volunteer+"/"+path.training, component: TrainingComponent},

  {path:path.about+"/"+path.links, component: LinksComponent},
  {path:path.about+"/"+path.sponsors, component: SponsorsComponent},
  {path:path.about+"/"+path.members, component: MembersComponent},
  {path:path.about+"/"+path.contact, component: ContactUsComponent},

  {path:path.events+"/"+path.volunteer_events, component: RecurringEventsComponent},
  {path:path.events+"/"+path.community_events, component: AnnualEventsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
