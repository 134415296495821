import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { CONTENTFUL } from '../../config/keys';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  retryCount = 3;
  private cdaClient = createClient({
    space: CONTENTFUL.space,
    accessToken: CONTENTFUL.accessToken,
  });

  private cdaClientCacheControl = createClient({
    space: CONTENTFUL.space,
    accessToken: CONTENTFUL.accessToken,
    headers: {'Cache-Control':'public, max-age=86400'}
  });

  constructor() { }

  async getNews(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONTENTFUL.contentTypeIds.newsAlert
    }, query))
    .then(res => res.items)
    .catch(error => {
      return error;
    })
  }

  async getPets(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONTENTFUL.contentTypeIds.pet
    }, query))
    .then(res => res.items)
    .catch(error => {
      return error;
    })
  }

  async getEvents(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONTENTFUL.contentTypeIds.event
    }, query))
    .then(res => res.items)
    .catch(error => {
      return error;
    })
  }

  async getPotm(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONTENTFUL.contentTypeIds.petsOfTheMonth
    }, query))
    .then(res => res.items)
    .catch(error => {
      return error;
    })
  }

  async getHours(query?: object): Promise<Entry<any>[]> {
    return this.cdaClientCacheControl.getEntries(Object.assign({
      content_type: CONTENTFUL.contentTypeIds.hours
    }, query))
    .then(res => res.items)
    .catch(error => {
      return error;
    })
  }

  async getMembers(query?: object): Promise<Entry<any>[]> {
    return this.cdaClientCacheControl.getEntries(Object.assign({
      content_type: CONTENTFUL.contentTypeIds.members
    }, query))
    .then(res => res.items)
    .catch(error => {
      return error;
    })
  }

  async getError(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: "wrong key to cause an error"
    }, query))
    .then(res => res.items)
    .catch(error => {
      return error;
    })
  }
}
