<app-header></app-header>
<app-bread-crumbs title="Corporate Partners" [crumbs]="['Home', 'Donate']" [href]="['', 'donate']"></app-bread-crumbs>
<div class="corporate-partners base-background">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="corporate-partners-header montserrat-light">Make a purchase from one of our partner companies</h2>
                <p class="corporate-partners-text montserrat">
                    Give money to the Animal Adoption Foundation with your everyday shopping! Use the links below to shop with our partner companies and we will receive a portion of the profit!              
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="corporate-partners-header montserrat-light">Amazon Smile</h2>
                <p class="corporate-partners-text montserrat">
                    Are you a frequent Amazon.com shopper? If so, there is an easy way for you to support AAF while buying your needed items. When you go to www.smile.amazon.com and do your online shopping you can select a charity. Simply select the Animal Adoption Foundation as your charitable organization and 0.5% of your eligible online purchase will go to helping homeless animals! AmazonSmile is the same Amazon you know. Same products, same prices, same service.
                </p>
                <a target="_blank" href="{{path.amazon_partner}}"><button class="btn btn-primary corporate-partners-button">Get Started</button></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="corporate-partners-header montserrat-light">Bark Box</h2>
                <p class="corporate-partners-text montserrat">
                    Get $5 off any BarkBox subscription and support the Animal Adoption Foundation!
                    For every coupon used on BarkBox.com, our rescue will get a $15 donation. Be sure to use our special promo code: BBX179VK.
                </p>
                <a target="_blank" href="{{path.barkbox_partner}}"><button class="btn btn-primary corporate-partners-button">Get Started</button></a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="corporate-partners-header montserrat-light">Kroger Neighborhood Reward Program</h2>
                <p class="corporate-partners-text montserrat">
                    Attention Kroger shoppers and AAF supporters.  Kroger offers a Kroger Rewards program called Kroger Community Rewards.  You can help the AAF by going to www.krogercommunityrewards.com and enrolling in the program.  It's quick and easy and all you need is a Kroger Plus Shopper's Card to get started.  Once you enroll, every time you use your Kroger Plus card, the AAF will receive 4% of whatever you spend thanks to Kroger. 
                    <br><br>Please take a few minutes to enroll in the Kroger Community Rewards Program.</p>
                    <ol>
                        <li class="montserrat">
                            Visit www.krogercommunityrewards.com or click the button below.
                        </li>
                        <li class="montserrat">
                            Register to create an account and sign in using your email address and password. 
                        </li>
                        <li class="montserrat">
                            Enter AAF's NPO # 80282, or the first three letters of the organization's name (or type animal adoption foundation), then click search.
                        </li>
                        <li class="montserrat">
                            Select the Animal Adoption Foundation by clicking on the circle to the left of AAF's name and save changes.
                        </li>
                    </ol>
                    <p class="corporate-partners-text montserrat">If haven't already designated the AAF as your favorite charity for your Kroger Plus card, please consider doing so. Kroger will donate 4% of what you spend to the homeless pets at the Animal Adoption Foundation! Thank you for your ongoing support!
                </p>
                <a target="_blank" href="{{path.kroger_partner}}"><button class="btn btn-primary corporate-partners-button">Get Started</button></a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>