<app-header></app-header>
<volunteer-banner></volunteer-banner>
<div class="volunteer-top base-background">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <h1 class="montserrat-light volunteer-header">Become a Volunteer With AAF!</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="volunteer-text montserrat volunteer-top-text">Thank you for your interest in volunteering. You can sign up for a community event or become an official AAF volunteer by completing the volunteer application and attending one or more levels of orientation.  We prefer that anyone interested in volunteering with the AAF sign up online but if you do not have the ability to do so, you can complete a paper form of the volunteer application in person at the shelter at the time of orientation. <br><br>
                    Your future with AAF may involve serving as a shelter volunteer, specializing in cat or dog care, fostering a pet in your home, joining an AAF committee, or more. Feel free to start in one role and let us know if you would like to try a different role. AAF offers a unique approach to volunteering by allowing volunteers to determine which opportunities fit their interests, goals, and availability.<br><br>
                    AAF welcomes Junior Volunteers who are under the age or 15 and volunteer alongside their responsible  adult. The adult will complete the secure online volunteer application and attend New Volunteer Orientation with the Junior Volunteer. It is very important to note that a child under the age of 15 years must be accompanied by one of their parents.  If you are looking to bring along a child outside of the immediate family, that child's parent will have to be in attendance as well.  <br><br>
                    To get started, complete the secure online volunteer application. You will receive an immediate confirmation with instructions for signing up for a new volunteer orientation. We refer to this volunteer orientation as “Level 1-New Volunteer Orientation” because it is the first step toward volunteering at AAF.<br><br>
                    We do ask that everyone attending an orientation arrive a little early so that we can check you in and assign your badge and lanyard.<br><br>
                    If you have trouble with the sign up, please email office@aafpets.org for assistance.</p>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 col-md-6">
                <div #action1 class="volunteer-top-box">
                    <h2 class="montserrat-light">Become a Volunteer by Completing These Steps</h2>
                    <a target="_blank" href="{{path.volunteer_application}}">
                        <button class="btn btn-primary montserrat">Complete volunteer application</button>
                    </a><br>
                    <a href="{{path.volunteer}}/{{path.training}}">
                        <button class="btn btn-primary montserrat">Attend training</button>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div #action2 class="volunteer-top-box">
                    <h2 class="montserrat-light">Learn More About Volunteering with AAF</h2>
                    <a href="{{path.volunteer}}/{{path.roles}}">
                        <button class="btn btn-primary montserrat">Volunteer Roles</button>
                    </a><br>
                    <a target="_blank" href="{{path.events}}/{{path.volunteer_events}}">
                        <button class="btn btn-primary montserrat">Volunteering Events</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="volunteer-mid">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <h2 class="montserrat volunteer-white-text">Volunteer Hours</h2>
            </div>
            <div class="col-12">
                <p class="montserrat volunteer-white-text">
                    Monday: 11am-6pm<br>
                    Tuesday - Friday: 11am-7pm<br>
                    Saturday: 11am-4pm<br>
                    Sunday: 12pm-4pm
                </p>
            </div>
        </div>
    </div>
</div>
<div class="volunteer-bottom base-background">
    <div class="container">
        <div class="row text-center">
            <div class="col-12">
                <h2 class="montserrat-light volunteer-header-bottom">Here are just some of the volunteer opportunities needed at the AAF.</h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12">
                <p class="montserrat">
                    *Dog Walkers <br>
                    *Cat Socialization<br>
                    *General lobby, cat tree cleaning, toy washing, etc.<br>
                    Special events (yard sale, charity auction, etc.)<br>
                    Landscaping/weed pulling<br>
                    Front desk help<br>
                    Fostering pets<br>
                    Bottle feeders (newborn kittens)<br>
                    Car rides for doggies/field trips<br>
                    Grooming<br>
                    Folding laundry<br>
                    Specific talents (web-site design, photography, grant writing, etc.)<br>
                    Adoption Counselors
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>