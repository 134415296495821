import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SENDINBLUE } from 'src/app/config/keys';
import { EventBriteResponse } from 'src/app/model/event.model';
import { SendInBlueEmail } from 'src/app/model/sendinblue.model';
import * as path from '../../common/constants/Path';

@Injectable({
  providedIn: 'root'
})
export class SendinblueService {

  requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders({
      'api-key': SENDINBLUE.key
    }), 
  };

  constructor(private http: HttpClient) { }

  sendEmail(email:SendInBlueEmail):Observable<any>{
    let emailBody = {
      "name":"Campaign sent via the API",
      "subject":"Customer Request From Site",
      "sender": { 
          "name": "Customer Request From Site", 
          "email":path.email
      },
      "to": [{"email":path.email}],
      "htmlContent": "Name: " + email.name + "<br>Email: " + email.email + "<br>Message: " + email.message
  }

    return this.http.post("https://api.sendinblue.com/v3/smtp/email", emailBody, this.requestOptions);
  }

  getError():Observable<EventBriteResponse> {
    return this.http.get<EventBriteResponse>("https://www.eventbriteapi.com/v3/organizations/" + "intentional error" + "/events/?status=live&order_by=name_asc&show_series_parent=true", this.requestOptions);
  }
}