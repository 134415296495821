import { Component, OnInit } from '@angular/core';
import * as path from '../../../common/constants/Path';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  path = path;
  constructor() { }

  ngOnInit(): void {
  }

}
