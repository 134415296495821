<app-header></app-header>
<app-bread-crumbs title="Animal Angels Fund" [crumbs]="['Home', 'Donate', 'Direct Donation']" [href]="['', 'donate', 'donate/direct-donation']"></app-bread-crumbs>
<div class="animal-angels base-background">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="animal-angels-header montserrat-light">Please Give to the Animal Angels</h2>
                <a target="_blank" href="{{path.donation_application}}"><button class="btn btn-primary animal-angels-button">Donate to Animal Angels Fund</button></a>
                <p class="animal-angels-text montserrat">
                    Imagine being homeless with no place to sleep, wondering where you might find your next meal. You feel afraid and alone.  Fortunately, you stumble upon a safe and warm shelter. As life seems to take a positive turn, you find out you have a serious medical issue but no health insurance to cover the hosptial visits and treatment.  Dealing with these life stressors would be devastating for most individuals.
                </p>
                <p class="animal-angels-text montserrat">
                    The unfortunate reality for some cats and dogs is they too lose their homes and face medical challenges.  They live on the streets, seek shelter and food from anyone they find and fight the elements. Some of these animals find their way to the Animal Adoption Foundation, where we do everything possible to ensure they get the care needed.  While we are fortunate to work with wonderful partners within the community, those supports and medical services come at a cost.  As a non-profit, funds available to care for animals with significant medical needs deplete quickly. Medical needs, whether acute or chronic, are expensive.  Recent medical procedures for AAF residents include open heart surgery (Janey the Boxer) and leg amputations for Hershey the Chihuahua and Clementine the Cat.  Ongoing care for cats like Chestnut  include emergency surgery for an ear hematoma, treatment for congestive heart failure and ongoing medical intervention.  Fergie suffered from Hepatic Lipidosis and had to have emergency surgery to save her life while Paws was in full-blown kidney failure and if not for the fund, he would not have survived.  
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h2 class="animal-angels-header montserrat-light">AAF Angels</h2>
                <p class="animal-angels-text montserrat">
                    Janey, Clementine, Chestnut, Hershey, Fergie and Paws all benefited from the Angel Animals Fund
                </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-10">
                <div class="container animal-angels-img-container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <img class="animal-angels-img" src="assets/img/angels/pet1.jpg" alt="animal angel recipient">
                        </div>
                        <div class="col-12 col-md-4">
                            <img class="animal-angels-img" src="assets/img/angels/pet2.jpg" alt="animal angel recipient">
                        </div>
                        <div class="col-12 col-md-4">
                            <img class="animal-angels-img" src="assets/img/angels/pet3.jpg" alt="animal angel recipient">
                        </div>
                        <div class="col-12 col-md-4">
                            <img class="animal-angels-img" src="assets/img/angels/pet4.jpg" alt="animal angel recipient">
                        </div>
                        <div class="col-12 col-md-4">
                            <img class="animal-angels-img" src="assets/img/angels/pet5.jpg" alt="animal angel recipient">
                        </div>
                        <div class="col-12 col-md-4">
                            <img class="animal-angels-img" src="assets/img/angels/pet6.jpg" alt="animal angel recipient">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="animal-angels-text montserrat">
                    These are just a few examples of the thousands of pets that AAF has helped over the years and we want to continue to be able provide medical care for homeless pets that goes beyond the routine vaccinations and spay/neuters that we already provide.  The work of AAF continues through donations to the Animal Angels Fund.  This fund is used exclusively for emergency situations or a pet with chronic medical needs.  The next time someone walks in with a dog or a cat that had just been hit by a car and the pet needs immediate medical attention, it is nice to know that we have the funding to enable us to take that pet in to our shelter.  Because of the number of pets that we have taken in with extenuating medical conditions, our Animal Angels Fund is running low.  Please consider making a donation to the fund today.  Our cats and dogs need your help.  Thank You!
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>